import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Device} from '../../models/device';
import {ISensorType, SensorType} from '../../models/device-sensor';
import {DeviceBackendService} from '../../services-backend/device-backend.service';
import {UserAccountResolverService} from '../../resolvers/user-account-resolver.service';
import {AlertService} from '../../services/alert.service';
import {WidgetBackendService} from '../../services-backend/widget-backend.service';
import {Widget} from '../../models/widget';
import {forkJoin} from 'rxjs';


interface WidgetType {
    value: 'map' | 'chart' | 'plain';
    name: string;
}

interface WidgetSize {
    id: number;
    size: string;
    label: 'XXL' | 'M' | 'S';
}

class WidgetConfig {
    type: WidgetType;
    size: WidgetSize;
    devices: Device[];
    sensors: ISensorType[];
    mainSensor: ISensorType | null;
    name: string;


    constructor(type: WidgetType, size: WidgetSize, devices: Device[], sensors: ISensorType[], mainSensor: ISensorType,
                name: string) {
        this.type = type;
        this.size = size;
        this.devices = devices;
        this.sensors = sensors;
        this.mainSensor = mainSensor;
        this.name = name;
    }

    public getType(): 'map' | 'chart' | 'plain' | null {
        if (this.type){
            return this.type.value;
        }
        else {
            return null;
        }
    }

    public getHeight(): number {
        if (this.size.label === 'XXL') {
            return 4;
        } else if (this.size.label === 'M') {
            return 4;
        } else {
            return 4;
        }
    }

    public getWidth(): number {
        if (this.size.label === 'XXL') {
            return 12;
        } else if (this.size.label === 'M') {
            return 8;
        } else {
            return 4;
        }
    }

    public getName(): string {
        if (this.name && this.name !== ''){
            return this.name;
        }
        if (this.devices.length > 0) {
            return this.devices[0].name;
        }
        return '';
    }

    public getDeviceSensorIds(): number[] {
        const ret: number[] = [];
        for (const device of this.devices){
            for (const deviceSensor of device.sensors){
                if (this.sensors.findIndex(el => el.id === deviceSensor.sensor_type.id) !== -1){
                    ret.push(deviceSensor.id);
                }
            }
        }
        return ret;
    }

    public getDeviceSensorForMainSensor(): number | null {
        if (this.mainSensor === null){
            return null;
        }
        for (const device of this.devices){
            for (const deviceSensor of device.sensors){
                if (this.mainSensor.id === deviceSensor.sensor_type.id){
                    return deviceSensor.id;
                }
            }
        }
        return null;
    }
}

@Component({
    selector: 'app-widget-create',
    templateUrl: './widget-create.component.html',
    styleUrls: ['./widget-create.component.scss']
})
export class WidgetCreateComponent implements OnInit {

    public widgetTypes: WidgetType[] = [
            {value: 'map', name: 'Mapa'},
            {value: 'chart', name: 'Wykres'},
            {value: 'plain', name: 'Tabela'},
        ];

    public widgetSizes: WidgetSize[] = [
            {id: 3, size: 'Duży', label: 'XXL'},
            {id: 2, size: 'Średni', label: 'M'},
            {id: 1, size: 'Mały', label: 'S'}
        ];

    public widget: WidgetConfig = new WidgetConfig(
        undefined, undefined, [], [], null, '',
    );
    devices: Device[] = [];
    sensorTypes: ISensorType[] = [];

    constructor(
        public router: Router,
        private deviceBackendService: DeviceBackendService,
        private userAccountResolverService: UserAccountResolverService,
        private alertService: AlertService,
        private widgetBackendService: WidgetBackendService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.userAccountResolverService.observable().subscribe(account => {
            const publicObservable = this.deviceBackendService.getOnlyPublic();
            const privateObservable = this.deviceBackendService.getPrivateByOrganizationId(account.active_organization);
            forkJoin([publicObservable, privateObservable]).subscribe(
                ([publicDevices, privateDevices]: [Device[], Device[]]) => {
                this.devices.length = 0;
                this.devices.push(...privateDevices);
                this.devices.push(...publicDevices);
            });
        });
    }

    public setWidgetType(type: WidgetType): void {
        this.widget.type = type;
        if (this.widget.getType() === 'plain'){
            let lastDevice: Device;
            for (const device of this.devices){
                if (this.widget.devices.indexOf(device) !== -1){
                    lastDevice = device;
                }
            }
            this.widget.devices.length = 0;
            if (lastDevice){
                this.widget.devices.push(lastDevice);
            }
        }
        this.updateSensorTypes();
    }

    public setWidgetSize(size: WidgetSize): void {
        this.widget.size = size;
    }

    public getInputType(): 'radio' | 'checkbox' {
        if (this.widget.getType() === 'plain'){
            return 'radio';
        }
        else {
            return 'checkbox';
        }
    }

    public addWidgetDevice(device: Device, event: Event): void {
        if ((event.target as HTMLInputElement).checked){
            const index = this.widget.devices.indexOf(device);
            if (this.widget.getType() === 'plain'){
                this.widget.devices.length = 0;
            }
            if (index === -1) {
                this.widget.devices.push(device);
            }
        }
        else {
            const index = this.widget.devices.indexOf(device);
            if (index !== -1) {
                this.widget.devices.splice(index, 1);
            }
        }
        this.updateSensorTypes();
    }

    private updateSensorTypes(): void{
        this.sensorTypes.length = 0;

        const nameToSensorType = new Map<string, ISensorType>();

        for (const device of this.widget.devices){
            for (const sensor of device.sensors){
                nameToSensorType.set(sensor.sensor_type.name, sensor.sensor_type);
            }
        }

        for (const sensorType of nameToSensorType.values()){
            this.sensorTypes.push(sensorType);
        }

        for (const sensorType of [...this.widget.sensors]){
            if (this.sensorTypes.findIndex(s => s.id === sensorType.id) === -1){
                this.widget.sensors.splice(this.widget.sensors.indexOf(sensorType), 1);
            }
        }
        this.updateMainSensor();
    }

    public setSensorType(sensorType: SensorType, event: Event): void {
        const index = this.widget.sensors.findIndex(s => s.id === sensorType.id);
        if ((event.target as HTMLInputElement).checked){
            if (index === -1) {
                this.widget.sensors.push(sensorType);
            }
        }
        else {
            if (index !== -1) {
                this.widget.sensors.splice(index, 1);
            }
        }
        this.updateMainSensor();
    }

    private updateMainSensor(): void {
        if (this.widget.mainSensor) {
            if (this.widget.sensors.findIndex(el => el.id === this.widget.mainSensor.id) === -1) {
                this.widget.mainSensor = null;
            }
        }
    }

    public setMainSensor(event: Event): void {
        const sensorId = parseInt((event.target as HTMLInputElement).value, 10);
        if (sensorId === -1){
            this.widget.mainSensor = null;
        }
        else {
            this.widget.mainSensor = this.widget.sensors.find(s => s.id === sensorId);
        }
    }

    public setWidgetName(event: Event): void {
        this.widget.name = (event.target as HTMLInputElement).value;
    }

    private showErrorAlert(message: string): void {
        this.alertService.error(message);
    }

    public addWidget(): void {

        if (!this.widget.type) {
            this.showErrorAlert('Wybierz typ widgetu');
        } else if (!this.widget.size) {
            this.showErrorAlert('Wybierz rozmiar widgetu');
        } else if (this.widget.devices.length === 0) {
            this.showErrorAlert('Wybierz urządzenie');
        } else if (this.widget.sensors.length === 0) {
            this.showErrorAlert('Wybierz sensor');
        } else {
            this.widgetBackendService.createWidget(new Widget({
                id: undefined,
                display_type: this.widget.type.value,
                height: this.widget.getHeight(),
                width: this.widget.getWidth(),
                name: this.widget.getName(),
                devices: this.widget.devices.map(el => el.id),
                device_sensors: this.widget.getDeviceSensorIds(),
                dashboard: parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10),
                main_sensor: this.widget.getDeviceSensorForMainSensor(),
                position_x: 0,
                position_y: 0,
            }))
            .subscribe((widget: Widget) => {
                this.router.navigate(['/dashboard', widget.dashboard]);
            });
        }
    }

    public isSensorTypeChecked(sensorType: ISensorType): boolean {
        return this.widget.sensors.findIndex(s => s.id === sensorType.id) !== -1;
    }

}
