import { Component, OnInit } from '@angular/core';
import {ProfileResolverService} from '../../resolvers/profile-resolver.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Dashboard} from '../../models/dashboard';
import {DashboardBackendService} from '../../services-backend/dashboard-backend.service';
import {MainLoaderService} from '../main/main-loader.service';
import {UserAccountResolverService} from '../../resolvers/user-account-resolver.service';

@Component({
    selector: 'app-dashboard-navigation',
    templateUrl: './dashboard-navigation.component.html',
    styleUrls: ['./dashboard-navigation.component.scss']
})
export class DashboardNavigationComponent implements OnInit {

    constructor(
        public profileResolver: ProfileResolverService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private dashboardService: DashboardBackendService,
        private mainLoaderService: MainLoaderService,
        private userAccountResolver: UserAccountResolverService,
    ) { }

    public dashboards: Dashboard[] = [];

    ngOnInit(): void {
        this.userAccountResolver.observable().subscribe(account => {
            this.dashboardService.getDashboards(account.active_organization).subscribe(dashboards => {
                this.dashboards.length = 0;
                this.dashboards.push(...dashboards);
            });
        });
    }

    deleteDashboard(id: number): void {
        if (confirm('Jesteś pewny?')) {
            const index = this.dashboards.findIndex(x => x.id === id);
            this.dashboardService.deleteDashboard(id).subscribe(_ => {
                this.dashboards.splice(index, 1);
                if (this.dashboards.length > 0){
                    this.router.navigate(['/dashboard', this.dashboards[0].id]);
                }
                else {
                    this.router.navigate(['/dashboard', ]);
                }
            });
        }
    }

    public showMainLoader(dashboard: Dashboard): void {
        const currentId = this.activatedRoute.snapshot.paramMap.get('id');
        if (dashboard.id.toString() !== currentId) {
            this.mainLoaderService.emit(true, 'dashboardLoad');
        }
    }
}
