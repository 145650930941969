/* tslint:disable:variable-name */
export class IUserAccount {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    active_organization: number;
}

export class IWriteUserAccount {
    first_name: string;
    last_name: string;
    active_organization: number;
}


export class UserAccount implements IUserAccount{
    active_organization: number;
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    username: string;

    constructor(active_organization: number, email: string, first_name: string, id: number, last_name: string, username: string) {
        this.active_organization = active_organization;
        this.email = email;
        this.first_name = first_name;
        this.id = id;
        this.last_name = last_name;
        this.username = username;
    }
}

export class UserAccountFactory {
    public static fromBackend(userAccount: IUserAccount): UserAccount {
        return new UserAccount(userAccount.active_organization, userAccount.email, userAccount.first_name,
            userAccount.id, userAccount.last_name, userAccount.username);
    }
    public static toBackend(userAccount: UserAccount): IWriteUserAccount {
        return {
            active_organization: userAccount.active_organization,
            first_name: userAccount.first_name,
            last_name: userAccount.last_name,
        };
    }
}
