<nav id="sidebar">
    <div class="logo">
        <p class="site-name">SMOGSENSORS.com</p>
        <p class="user-name">Witaj <span *ngIf="this.profileResolver.profile.getFullName()">{{ this.profileResolver.profile.getFullName() }}</span></p>
    </div>
    <div *ngFor="let dashboard of dashboards; index as id" id="button-{{dashboard.id}}" [routerLinkActive]="'active'" class="dash-button">
        <div class="dash-name" (click)="showMainLoader(dashboard)" [routerLink]="['/dashboard', dashboard.id]">
            {{ dashboard.name }}
        </div>
        <button (click)="deleteDashboard(dashboard.id)">&times;</button>
    </div>
    <button class="add-dashboard" (click)="router.navigate(['dashboard', 'create'])">Dodaj dashboard +</button>
</nav>
