<div class="register">
    <div class="register-form">
        <h1>Rejestracja</h1>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Imię</mat-label>
                <input matInput formControlName="first_name">
                <mat-error *ngIf="f.first_name.hasError('required')">Imię jest <strong>wymagane</strong></mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Nazwisko</mat-label>
                <input matInput formControlName="last_name">
                <mat-error *ngIf="f.last_name.hasError('required')">Nazwisko jest <strong>wymagane</strong></mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Adres email</mat-label>
                <input matInput formControlName="email" type="email">
                <mat-error *ngIf="f.email.hasError('required')">Email jest <strong>wymagany</strong></mat-error>
                <mat-error *ngIf="f.email.hasError('server')">{{ f.email.errors.server }}</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Powtórz adres email</mat-label>
                <input matInput formControlName="username" type="email">
                <mat-error *ngIf="f.username.hasError('required')">Powtórzenie adresu email jest <strong>wymagane</strong></mat-error>
                <mat-error *ngIf="f.username.hasError('notSameMail')">Adresy email się nie pokrywają</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Hasło</mat-label>
                <input matInput formControlName="password" type="password">
                <mat-error *ngIf="f.password.hasError('required')">Hasło jest <strong>wymagane</strong></mat-error>
                <mat-error *ngIf="f.password.hasError('minlength')">Hasło musi mieć conajmniej 8 znaków</mat-error>
                <mat-error *ngIf="f.password.hasError('server')">{{ f.password.errors.server }}</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Powtórz hasło</mat-label>
                <input matInput formControlName="password_confirm" type="password">
                <mat-error *ngIf="f.password_confirm.hasError('required')">Powtórzenie hasła jest <strong>wymagane</strong></mat-error>
                <mat-error *ngIf="f.password_confirm.hasError('minlength')">Hasło musi mieć conajmniej 8 znaków</mat-error>
                <mat-error *ngIf="f.password_confirm.hasError('notSame')">Hasła się nie pokrywają</mat-error>
            </mat-form-field>

            <div class="form-group">
                <button [disabled]="loading" class="btn btn-custom btn-register">
                    Zarejestruj
                </button>
                <p class="cancel"><a routerLink="/login">Anuluj</a></p>
            </div>
        </form>
    </div>
</div>
