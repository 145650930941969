import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, ReplaySubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new ReplaySubject<any>();
    private keepAfterRouteChange = false;

    constructor(private router: Router, private snackBar: MatSnackBar) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false): void {
        console.log(message);
        this.openSnackBar(message);
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'success', text: message });
    }

    private openSnackBar(message: string): void {
        this.snackBar.open(message, null, {
            duration: 2000,
        });
    }


    error(message: string, keepAfterRouteChange = false): void {
        this.openSnackBar(message);
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'error', text: message });
    }

    clear(): void {
        // clear by calling subject.next() without parameters
        this.subject.next();
    }
}
