<div class="wrapper">
<div class="create-widget-step first-step">
    <div class="header-wrapper">
        <h4 class="modal-title">01. Wybierz widget</h4>
        <button type="button" class="close" aria-label="Close" (click)="router.navigate(['dashboard'])">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="create-widget-substep widget-choose-type">
        <p class="title">Typ widgetu</p>
        <div class="row">
            <div *ngFor="let type of widgetTypes" class="col-4">
                <label class="type">
                    <input type="radio" name="widget_type" [value]="type.value" (change)="setWidgetType(type)"
                           required="required" placeholder="{{ type.name }}"/>
                    <img src="assets/images/widgets/widget-{{ type.value }}.jpg" />
                    <span>{{ type.name }}</span>
                    <span class="tick">&#10004;</span>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="create-widget-step second-step">
    <div class="create-widget-substep widget-choose-size">

        <p class="title">Rozmiar widgetu</p>
        <div class="row">
            <div *ngFor="let size of widgetSizes" class="col-4 dimension {{ size.label }}">
                <label class="size">
                    <input type="radio" name="size" [value]="size.id" (change)="setWidgetSize(size)"
                           placeholder="{{ size.label }}"/>
                    <span>{{ size.label }}</span>
                    <span class="tick">&#10004;</span>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="create-widget-step third-step">

    <div class="header-wrapper">
        <h4 class="modal-title">02. Wybierz urządzenie</h4>
    </div>
    <div class="flex-wrapper">
        <div *ngFor="let device of devices" class="col-4">
            <label>
                <input [type]="getInputType()" [value]="device.id" name="device"
                       (change)="addWidgetDevice(device, $event)">
                <span>{{ device.name }}</span>
                <span class="select-box"></span>
            </label>
        </div>
    </div>
</div>

<div class="create-widget-step fourth-step">

    <div class="header-wrapper">
        <h4 class="modal-title">03. Wybierz sensory</h4>
    </div>
    <div class="flex-wrapper">
        <div *ngFor="let sensorType of sensorTypes;" class="col-2">
            <label>
                <input type="checkbox" [checked]="isSensorTypeChecked(sensorType)" [value]="sensorType.id" [name]="sensorType.name" [attr.data-unit]="sensorType.units"
                       (change)="setSensorType(sensorType, $event)">
                <span class="sensor sensor-{{ sensorType.code }}">{{ sensorType.code }}</span>
            </label>
        </div>
    </div>
    <div *ngIf="widget.getType() === 'map'">
        <p class="description">Ustaw główny sensor</p>
        <select class="form-control" name="device" (change)="setMainSensor($event)">
            <option value="-1">-- Wybierz główny sensor --</option>
            <option *ngFor="let sensor of widget.sensors" [value]="sensor.id">{{ sensor.name }}</option>
        </select>
    </div>
    <p class="title">Nazwa widgetu</p>
    <input type="text" class="form-control col-6" (input)="setWidgetName($event)" />

</div>

    <div class="modal-footer summary">
        <button mat-raised-button color="primary" (click)="addWidget()">Dodaj widget</button>
    </div>
</div>
