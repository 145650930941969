import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {MainLoaderService} from './components/main/main-loader.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
    title = 'sensors-frontend';
    private routerSubscription: Subscription = null;

    constructor(
        private router: Router,
        private mainLoaderService: MainLoaderService,

    ) { }

    ngOnInit(): void {
        this.routerSubscription = this.router.events.subscribe((e: RouterEvent) => {
            this.checkEvent(e);
        });
    }

    ngOnDestroy(): void {
        if (this.routerSubscription !== null){
            this.routerSubscription.unsubscribe();
            this.routerSubscription = null;
        }
    }

    checkEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.mainLoaderService.emit(true, 'routerNavigation');
        }

        else if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.mainLoaderService.emit(false, 'routerNavigation');
        }
    }

}
