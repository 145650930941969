<header class="settings">
	<div class="logo">
		<p class="site-name">SMOGSENSORS.com</p>
	</div>
	<div class="settings-menu" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
		<a [routerLink]="['user']">Użytkownik</a>
		<a [routerLink]="['organization']" *ngIf="showMenuItem">Organizacja</a>
		<a [routerLink]="['members']" *ngIf="showMenuItem">Członkowie organizacji</a>
		<a [routerLink]="['devices']" *ngIf="showMenuItem || isIndividualOrganization">Urządzenia</a>
	</div>
	<div class="organization-name">
		{{ organization.name }}
	</div>
	<span class="divider">|</span>
	<div class="actions">
		<button (click)="authenticationService.logout()">&times;&nbsp;&nbsp;&nbsp;wyloguj</button>
	</div>
</header>
<div class="settings-content">
	<router-outlet></router-outlet>
</div>