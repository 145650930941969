import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {IWidget, Widget} from '../models/widget';
import {ErrorHandlerService} from '../services/error-handler.service';
import { saveAs } from 'file-saver';
import {AlertService} from '../services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class WidgetBackendService {

    constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
        private alertService: AlertService,
    ) { }

    private static getFileNameFromHttpResponse(httpResponse: HttpResponse<Blob>): string {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition')
            || 'attachment; filename=measurements.csv';
        const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        return result.replace(/"/g, '');
    }

    getWidget(id: number): Observable<Widget> {
        const url = `${environment.url}/widgets/${id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.get<IWidget>(url)).pipe(map(widget => {
            return new Widget(widget);
        }));
    }

    getWidgets(dashboardId: number): Observable<Widget[]> {
        const url = `${environment.url}/widgets/?dashboard_id=${dashboardId}`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.get<IWidget[]>(url)).pipe(
            map(widgets => { return widgets.map(el => new Widget(el));
            }));
    }

    deleteWidget(id: number): Observable<void> {
        const url = `${environment.url}/widgets/${id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.delete<void>(url));
    }

    setWidget(widget: Widget): Observable<Widget> {
        const url = `${environment.url}/widgets/${widget.id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.put<IWidget>(url, widget)).pipe(
            map(res => { return new Widget(res);
            }));
    }

    createWidget(widget: Widget): Observable<Widget> {
        const url = `${environment.url}/widgets/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.post<IWidget>(url, widget))
            .pipe(map(res => { return new Widget(res);
            }));
    }

    downloadFile(deviceSensors: number[], start: Date, end: Date): any {
        const url = `${environment.url}/measurements/?format=csv&ordering=measurement_time&device_sensor_ids=`
            + deviceSensors + `&sensor_data_start=` + start.toISOString() + `&sensor_data_end=` + end.toISOString();
        this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(catchError(error => {
            this.alertService.error('Ilość danych do pobrania jest zbyt duża. Ogranicz zakres czasowy');
            return new Observable<never>();
        })).subscribe(response => {
            saveAs(response.body, WidgetBackendService.getFileNameFromHttpResponse(response));
        });
    }
}
