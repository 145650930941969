import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlayerService} from '../../services/player.service';
import {GlobalDateService} from '../../services/global-date.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnDestroy {

    public dateSubscription: Subscription = null;

    constructor(
        public playerService: PlayerService,
        public globalDateService: GlobalDateService,
    ) { }

    ngOnInit(): void {
        if (this.dateSubscription){
            this.dateSubscription.unsubscribe();
        }
        this.dateSubscription = this.globalDateService.observable().subscribe(_ => {
            this.playerService.reset();
        });
    }

    ngOnDestroy(): void {
        if (this.dateSubscription){
            this.dateSubscription.unsubscribe();
            this.dateSubscription = null;
        }
    }

}
