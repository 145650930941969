import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Organization} from './model/organization';
import {OrganizationBackendService} from './organization-backend.service';
import {UserAccountResolverService} from '../../resolvers/user-account-resolver.service';
import {UserAccount} from '../../models/user-account';
import {mergeMap, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrganizationResolverService implements Resolve<Organization> {
    constructor(private organizationService: OrganizationBackendService,
                private userAccountResolver: UserAccountResolverService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization> {
        return this.userAccountResolver.observable().pipe(take(1), mergeMap((userAccount: UserAccount) => {
            const organizationId = userAccount.active_organization;
            return this.organizationService.getOrganization(organizationId);
        }));
    }
}
