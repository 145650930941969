import { Injectable } from '@angular/core';
import {Subject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WidgetFocusService {


    private clickSubject: Subject<Date> = new Subject<Date>();

    constructor() { }

    public emitValue(date: Date): void{
        this.clickSubject.next(date);
    }

    public subscribe(callback: (date: Date) => void): Subscription {
        return this.clickSubject.asObservable().subscribe(date => callback(date));
    }
}
