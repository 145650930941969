import { Component, OnInit } from '@angular/core';
import {MqttMeasurementService} from '../../services/mqtt-measurement.service';

@Component({
    selector: 'app-mqtt',
    templateUrl: './mqtt.component.html',
    styleUrls: ['./mqtt.component.scss']
})
export class MqttComponent implements OnInit {

    constructor(
        public mqttService: MqttMeasurementService,
    ) { }

    ngOnInit(): void {
    }

    changeMqttState(): void {
        this.mqttService.setEnabled(!this.mqttService.currentState());
    }
}
