<mat-table [dataSource]="userInvites" *ngIf="userInvites" class="mat-effects">

    <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef >Użytkownik</mat-header-cell>
        <mat-cell *matCellDef="let userInvite"> {{ userInvite.user_data.email }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="permissions">
        <mat-header-cell *matHeaderCellDef >Uprawnienia</mat-header-cell>
        <mat-cell *matCellDef="let userInvite">
            <mat-select [(value)]="userInvite.selectedPermission"
                        (selectionChange)="updateInvitePermissions(userInvite, $event)">
                <mat-option *ngFor="let permission of availablePermissions" [value]="permission">
                    {{permission.name}}
                </mat-option>
            </mat-select>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">Akcje</mat-header-cell>
        <mat-cell *matCellDef="let userInvite" class="justify-content-end">
            <button (click)="deleteInvite(userInvite)">Usuń</button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: false"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
</mat-table>

<div class="organization-invite">
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email..." [value]="newInviteEmail"
               (input)="inviteEmailChange($event)">
    </mat-form-field>
    <button (click)="sendInvite()" [disabled]="!inviteButtonEnabled">Wyślij zaproszenie</button>
</div>
