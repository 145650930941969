import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OrganizationBackendService} from '../../organization/organization-backend.service';
import {forkJoin} from 'rxjs';
import {AlertService} from '../../../services/alert.service';
import {Organization} from '../../organization/model/organization';
import {MatTable} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {UserAccount} from '../../../models/user-account';
import {UserOrganization} from '../../organization/model/user-organization';
import {ProfileBackendService} from '../../../services-backend/profile-backend.service';
import {ProfileResolverService} from '../../../resolvers/profile-resolver.service';

@Component({
    selector: 'app-user-organization-settings',
    templateUrl: './user-organization-settings.component.html',
    styleUrls: ['./user-organization-settings.component.scss']
})
export class UserOrganizationSettingsComponent implements OnInit {
    public organizationCreateButtonEnabled = false;
    public newOrganizationName = '';

    public organizations: Organization[] = [];
    private organizationIdToUserOrganization = new Map<number, UserOrganization>();
    @ViewChild(MatTable) matPositions: MatTable<any>;
    @Input() private userAccount: UserAccount = null;
    columnsToDisplay: string[] = ['name', 'actions'];
    private individualOrganization = null;

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private profileBackendService: ProfileBackendService,
        private profileResolverService: ProfileResolverService,
    ) { }

    ngOnInit(): void {
        this.refreshData();

    }

    private refreshData(): void {
        forkJoin([
            this.organizationBackendService.getAllOrganizations(),
            this.organizationBackendService.getAllUserOrganizationsForUser(this.userAccount.id)
        ])
        .subscribe(([organizations, userOrganizations]: [Organization[], UserOrganization[]]) => {

            this.organizationIdToUserOrganization.clear();
            this.organizations.length = 0;

            for (const userOrganization of userOrganizations){
                const organizationIndex = organizations.findIndex(
                    organization => organization.id === userOrganization.organization);
                if (organizationIndex !== -1) {
                    const organization = organizations[organizationIndex];
                    if (!organization.individual) {
                        this.organizations.push(organization);
                        this.organizationIdToUserOrganization.set(organization.id, userOrganization);
                    }
                    else {
                        this.individualOrganization = organization;
                    }
                }
            }

            this.matPositions.renderRows();
        });
    }

    public organizationNameChange(event: Event): void {
        this.newOrganizationName = (event.target as HTMLInputElement).value;
        this.organizationCreateButtonEnabled = this.newOrganizationName !== '';
    }

    public createOrganization(): void {
        this.organizationBackendService.createOrganization(this.newOrganizationName)
            .subscribe(_ => {
                this.alertService.success('Utworzono organizację');
                this.organizationCreateButtonEnabled = false;
                this.newOrganizationName = '';
                this.refreshData();
            });
    }

    public removeUserOrganization(organization: Organization): void {
        // TODO add confirmation
        const userOrganization = this.organizationIdToUserOrganization.get(organization.id);
        this.organizationBackendService.deleteUserOrganization(userOrganization)
            .subscribe(_ => {
                this.alertService.success('Usunięto członkostwo');
                this.organizationIdToUserOrganization.delete(organization.id);
                const index = this.organizations.findIndex(org => org.id === organization.id);
                if (index !== -1) {
                    this.organizations.splice(index, 1);
                    this.matPositions.renderRows();
                }
            });
    }

    public changeToIndividual(): void {
        this.userAccount.active_organization = this.individualOrganization.id;
        this.profileBackendService.editUserAccount(this.userAccount)
            .subscribe(userAccount => {
                this.alertService.success('Wystapiono z organizacji');
                this.userAccount.active_organization = userAccount.active_organization;
                this.profileResolverService.refreshProfile();
            });
    }

    public isCurrent(organization: Organization): boolean {
        return organization.id === this.userAccount.active_organization;
    }

    public joinOrganization(organization: Organization): void {
        this.userAccount.active_organization = organization.id;
        this.profileBackendService.editUserAccount(this.userAccount)
            .subscribe(userAccount => {
                this.alertService.success('Dołączono do organizacji');
                this.userAccount.active_organization = userAccount.active_organization;
                this.profileResolverService.refreshProfile();
            });
    }
}
