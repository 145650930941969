import { Injectable } from '@angular/core';
import {MarkerMeasurementDate, MarkerMeasurementValue, MeasurementMarker} from '../models/measurement-marker';
import {Measurement} from '../models/measurement';
import {SensorType} from '../models/device-sensor';
import {main} from '@angular/compiler-cli/src/main';

@Injectable({
    providedIn: 'root'
})
export class MapMarkerConverterService {

    constructor() {}

    private static getDecidingValue(measurementDates: MarkerMeasurementDate [], mainSensorType?: SensorType): {
        measurement: MarkerMeasurementValue,
        date: MarkerMeasurementDate,
    } {

        let preferredMeasurement: MarkerMeasurementValue;
        let preferredDate: MarkerMeasurementDate;
        let worstMeasurement: MarkerMeasurementValue = measurementDates[0].values[0];
        let worstDate: MarkerMeasurementDate = measurementDates[0];

        if (mainSensorType) {
            for (const date of measurementDates) {
                for (const value of date.values) {
                    if (value.sensorType.name === mainSensorType.name) {
                        if (preferredMeasurement === undefined || preferredMeasurement.value < value.value) {
                            preferredMeasurement = value;
                            preferredDate = date;
                        } else if (worstMeasurement.value < value.value) {
                            worstMeasurement = value;
                            worstDate = date;
                        }
                    }
                }
            }
        }

        if (preferredMeasurement === undefined) {
            return {
                measurement: worstMeasurement,
                date: worstDate,
            };
        } else {
            return {
                measurement: preferredMeasurement,
                date: preferredDate,
            };
        }
    }

    measurementsToMarkers(measurements: Measurement[], mainSensorId: number): MeasurementMarker [] {
        const measurementLength = measurements.length;
        const markers: MeasurementMarker [] = [];
        // console.log('MapMarkerConverter', 'starting conversion of', measurementLength);

        // latitude -> longitude -> timestamp -> element
        const measurementMarkerValues: Map<number, Map<number, Map<number, MarkerMeasurementValue []>>> =
            this.getValueMap(measurements);
        const measurementMarkerDates: Map<number, Map<number, MarkerMeasurementDate []>> =
            this.getDateMap(measurements);
        let sensorType: SensorType;
        for (const measurement of measurements) {
            if (measurement.deviceSensorId === mainSensorId) {
                sensorType = measurement.sensorType;
                break;
            }
        }
        if (!sensorType && measurements.length > 0) {
            sensorType = measurements[0].sensorType;
        }
        for (const lat of measurementMarkerDates.keys()) {
            for (const lon of measurementMarkerDates.get(lat).keys()) {
                const measurementDates = measurementMarkerDates.get(lat).get(lon);
                for (const measurementDate of measurementDates) {
                    measurementDate.values = measurementMarkerValues.get(lat).get(lon).get(measurementDate.date.getTime());
                }

                const decidingElem = MapMarkerConverterService.getDecidingValue(measurementDates, sensorType);
                const decidingValue = decidingElem.measurement;
                const decidingDate = decidingElem.date;

                const marker = new MeasurementMarker(0, lat, lon,
                    decidingValue.value, decidingValue.deviceSensorId, sensorType,
                    decidingDate.date,
                    measurementDates);

                if (!(lat === 0 && lon === 0 || lat >= 90 || lat <= -90 || lon > 180 || lon < -180)) {
                    markers.push(marker);
                }
            }
        }
        return markers;
    }


    private getDateMap(measurements: Measurement[]): Map<number, Map<number, MarkerMeasurementDate []>> {
        const measurementMarkerDates = new Map<number, Map<number, MarkerMeasurementDate []>>();
        const dates = new Map<number, Map<number, Set<number>>>();

        measurements.forEach(measurement => {

            if (measurement.latitude !== 0 || measurement.longitude !== 0) {
                const lat = measurement.latitude;
                const lon = measurement.longitude;

                if (!measurementMarkerDates.has(lat)) {
                    measurementMarkerDates.set(lat, new Map<number, MarkerMeasurementDate []>());
                    dates.set(lat, new Map<number, Set<number>>());
                }
                const measurementMarkerDatesIn1 = measurementMarkerDates.get(lat);
                const datesIn1 = dates.get(lat);
                if (!measurementMarkerDatesIn1.has(lon)) {
                    measurementMarkerDatesIn1.set(lon, []);
                    datesIn1.set(lon, new Set());
                }
                const measurementDates: MarkerMeasurementDate [] = measurementMarkerDatesIn1.get(lon);
                const datesIn2 = datesIn1.get(lon);

                if (!datesIn2.has(measurement.measurementTime.getTime())) {
                    measurementDates.push(new MarkerMeasurementDate(measurement.measurementTime, []));
                    datesIn2.add(measurement.measurementTime.getTime());
                }

            }
        });
        return measurementMarkerDates;
    }

    private getValueMap(measurements: Measurement[]): Map<number, Map<number, Map<number, MarkerMeasurementValue []>>> {
        const measurementMarkerValues = new Map<number, Map<number, Map<number, MarkerMeasurementValue []>>>();

        measurements.forEach(measurement => {

            if (measurement.latitude !== 0 || measurement.longitude !== 0) {
                const lat = measurement.latitude;
                const lon = measurement.longitude;
                const time = measurement.measurementTime.getTime();

                if (!measurementMarkerValues.has(lat)) {
                    measurementMarkerValues.set(lat, new Map<number, Map<number, MarkerMeasurementValue []>>());
                }
                const measurementMarkerValuesIn1 = measurementMarkerValues.get(lat);
                if (!measurementMarkerValuesIn1.has(lon)) {
                    measurementMarkerValuesIn1.set(lon, new Map<number, MarkerMeasurementValue []>());
                }

                const measurementMarkerValuesIn2 = measurementMarkerValuesIn1.get(lon);
                if (!measurementMarkerValuesIn2.has(time)) {
                    measurementMarkerValuesIn2.set(time, []);
                }

                const measurementValues: MarkerMeasurementValue [] = measurementMarkerValuesIn2.get(time);

                measurementValues.push(new MarkerMeasurementValue(measurement.value, measurement.sensorType,
                    measurement.deviceSensorId));
            }
        });
        return measurementMarkerValues;
    }
}
