import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {IOrganization, Organization, OrganizationFactory} from './model/organization';
import {HttpClient} from '@angular/common/http';
import {
    IUserApplication,
    IWriteUserApplication,
    UserApplication,
    UserApplicationFactory
} from './model/user-application';
import {
    IUserOrganization,
    IWriteUserOrganization,
    UserOrganization,
    UserOrganizationFactory
} from './model/user-organization';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {IUserInvite, IUserInvitePermission, IWriteUserInvite, UserInvite, UserInviteFactory} from './model/user-invite';

@Injectable({
    providedIn: 'root'
})
export class OrganizationBackendService {

    constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    public getOrganization(id: number): Observable<Organization> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IOrganization>(`${environment.url}/organization/${id}/`))
            .pipe(
                map((organization: IOrganization) => {
                    return OrganizationFactory.fromBackend(organization);
                })
            );
    }

    public editOrganization(organization: Organization): Observable<Organization> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .put<IOrganization>(
                `${environment.url}/organization/${organization.id}/`,
                OrganizationFactory.toBackend(organization)))
            .pipe(
                map((ret: IOrganization) => {
                    return OrganizationFactory.fromBackend(ret);
                })
            );
    }

    public createOrganization(name: string): Observable<Organization> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .post<IOrganization>(`${environment.url}/organization/`, {name}))
            .pipe(
                map((ret: IOrganization) => {
                    return OrganizationFactory.fromBackend(ret);
                })
            );
    }

    public getAllOrganizations(): Observable<Organization[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IOrganization[]>(`${environment.url}/organization/`))
            .pipe(
                map((ret: IOrganization[]) => {
                    return ret.map(organization => OrganizationFactory.fromBackend(organization));
                })
            );
    }

    public acceptApplication(application: UserApplication): Observable<UserOrganization> {
        const userOrganization: IWriteUserOrganization = {
            organization: application.organization,
            permissions: [{permission: 'read_all'}],
            user: application.user
        };
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .post<IUserOrganization>(`${environment.url}/userOrganization/`, userOrganization))
            .pipe(
                map((ret: IUserOrganization) => {
                    return UserOrganizationFactory.fromBackend(ret);
                })
            );
    }

    public sendApplication(organizationName: string): Observable<UserApplication> {
        const userApplication: IWriteUserApplication = UserApplicationFactory.toBackend(organizationName);
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .post<IUserApplication>(`${environment.url}/application/`, userApplication))
            .pipe(
                map((ret: IUserApplication) => {
                    return UserApplicationFactory.fromBackend(ret);
                })
            );
    }

    public denyApplication(application: UserApplication): Observable<void> {
        return this.errorHandlerService.pipeThroughErrorHandler(
            this.http.delete<void>(`${environment.url}/application/${application.id}/`));
    }

    public editUserOrganization(userOrganization: UserOrganization): Observable<UserOrganization>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .put<IUserOrganization>(
                `${environment.url}/userOrganization/${userOrganization.id}/`,
                UserOrganizationFactory.toBackend(userOrganization)))
            .pipe(
                map((ret: IUserOrganization) => {
                    return UserOrganizationFactory.fromBackend(ret);
                })
            );
    }

    public deleteUserOrganization(userOrganization: UserOrganization): Observable<void>{
        return this.errorHandlerService.pipeThroughErrorHandler(
            this.http.delete<void>(`${environment.url}/userOrganization/${userOrganization.id}/`));
    }

    public deleteOrganization(organizationId: number): Observable<void>{
        return this.errorHandlerService.pipeThroughErrorHandler(
            this.http.delete<void>(`${environment.url}/organization/${organizationId}/`));
    }

    public getAllUserApplications(organizationId: number): Observable<UserApplication[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserApplication[]>(`${environment.url}/application/?organization=${organizationId}`))
            .pipe(
                map((ret: IUserApplication[]) => {
                    return ret.map(userApplication => UserApplicationFactory.fromBackend(userApplication));
                })
            );
    }

    public getAllUserOrganizations(organizationId: number): Observable<UserOrganization[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserOrganization[]>(`${environment.url}/userOrganization/?organization=${organizationId}`))
            .pipe(
                map((ret: IUserOrganization[]) => {
                    return ret.map(userOrganization => UserOrganizationFactory.fromBackend(userOrganization));
                })
            );
    }

    public getAllUserOrganizationsForUser(userId: number): Observable<UserOrganization[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserOrganization[]>(`${environment.url}/userOrganization/?user=${userId}`))
            .pipe(
                map((ret: IUserOrganization[]) => {
                    return ret.map(userOrganization => UserOrganizationFactory.fromBackend(userOrganization));
                })
            );
    }

    public getAllUserApplicationsForUser(userId: number): Observable<UserApplication[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserApplication[]>(`${environment.url}/application/?user=${userId}`))
            .pipe(
                map((ret: IUserApplication[]) => {
                    return ret.map(userApplication => UserApplicationFactory.fromBackend(userApplication));
                })
            );
    }

    public acceptInvite(invite: UserInvite): Observable<UserOrganization> {

        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .post<IUserOrganization>(`${environment.url}/invite/${invite.id}/accept`, {}))
            .pipe(
                map((ret: IUserOrganization) => {
                    return UserOrganizationFactory.fromBackend(ret);
                })
            );
    }

    public createInvite(organizationId: number, email: string, permission: IUserInvitePermission): Observable<UserInvite> {
        const userInvite: IWriteUserInvite = UserInviteFactory.toBackend(email, organizationId, permission);
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .post<IUserInvite>(`${environment.url}/invite/`, userInvite))
            .pipe(
                map((ret: IUserInvite) => {
                    return UserInviteFactory.fromBackend(ret);
                })
            );
    }

    public deleteInvite(invite: UserInvite): Observable<void> {
        return this.errorHandlerService.pipeThroughErrorHandler(
            this.http.delete<void>(`${environment.url}/invite/${invite.id}/`));
    }

    public getInvitesForUser(userId: number): Observable<UserInvite[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserInvite[]>(`${environment.url}/invite/?user=${userId}`))
            .pipe(
                map((ret: IUserInvite[]) => {
                    return ret.map(userInvite => UserInviteFactory.fromBackend(userInvite));
                })
            );
    }

    public getInvitesForOrganization(organizationId: number): Observable<UserInvite[]>{
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .get<IUserInvite[]>(`${environment.url}/invite/?organization=${organizationId}`))
            .pipe(
                map((ret: IUserInvite[]) => {
                    return ret.map(userInvite => UserInviteFactory.fromBackend(userInvite));
                })
            );
    }

    public editInvite(invite: UserInvite): Observable<UserInvite> {
        const data = UserInviteFactory.toBackendUpdate(invite);
        return this.errorHandlerService.pipeThroughErrorHandler(this.http
            .put<IUserInvite>(`${environment.url}/invite/${invite.id}/`, data))
            .pipe(
                map((userInvite: IUserInvite) => {
                    return UserInviteFactory.fromBackend(userInvite);
                })
            );
    }
}
