<div class="mat-progress-bar-wrapper">
    <mat-progress-bar mode="indeterminate"
                      [ngClass]="{'attr-visibility-hidden': !mainLoaderService.currentValue()}"
    ></mat-progress-bar>
</div>
<app-alert></app-alert>
<div class="dashboard-with-sidebar">
    <app-dashboard-navigation ></app-dashboard-navigation>
    <div class="navigation-with-top-bar">
        <app-top-navigation [dashboard]="selectedDashboard"></app-top-navigation>
        <app-dashboard *ngIf="selectedDashboard" [dashboard]="selectedDashboard"></app-dashboard>
    </div>
</div>

<div class="container">
    <router-outlet></router-outlet>
</div>