import { Component, OnInit, Input } from '@angular/core';
import {Map, control, Control} from 'leaflet';

import * as L from 'leaflet';

import 'leaflet.fullscreen/Control.FullScreen.js';

declare module 'leaflet' {

    namespace control {

        function fullscreen(v: any): Control;

    }
}

@Component({

    selector: 'app-fullscreen-control',

    template: '',

    styleUrls: []

})

export class FullscreenControlComponent implements OnInit {

    // tslint:disable-next-line:variable-name
    private _map: Map;

    constructor() { }

    ngOnInit(): void {

    }

    @Input() set map(map: Map){

        this._map = map;

        control.fullscreen({

            position: 'topleft',

            title: 'View Fullscreen',

            titleCancel: 'Exit Fullscreen',

        }).addTo(map);

        map.on('enterFullscreen', () => map.invalidateSize());

        map.on('exitFullscreen', () => map.invalidateSize());
    }

    get map(): Map {

        return this._map;
    }

}
