import { Injectable } from '@angular/core';
import {Dashboard, IDashboard, IWriteDashboard} from '../models/dashboard';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ErrorHandlerService} from '../services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardBackendService {


    constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    getDashboard(id: number, organizationId: number): Observable<Dashboard> {
        const url = `${environment.url}/dashboards/${id}/?organization=${organizationId}`;
        return this.http.get<IDashboard>(url).pipe(map(dashboard => {
            return new Dashboard(dashboard);
        }));
    }

    deleteDashboard(id: number): Observable<void> {
        const url = `${environment.url}/dashboards/${id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.delete<void>(url));
    }

    getDashboards(organizationId: number): Observable<Dashboard[]> {
        const url = `${environment.url}/dashboards/?organization=${organizationId}`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.get<IDashboard[]>(url)).pipe(map(dashboards => {
            return dashboards.map(el => new Dashboard(el));
        }));
    }

    setDashboard(dashboard: Dashboard): Observable<Dashboard> {
        const url = `${environment.url}/dashboards/${dashboard.id}/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.put<IDashboard>(url, dashboard)).pipe(map(res => {
            return new Dashboard(res);
        }));
    }

    createDashboard(dashboard: IWriteDashboard): Observable<Dashboard> {
        const url = `${environment.url}/dashboards/`;
        return this.errorHandlerService.pipeThroughErrorHandler(this.http.post<IDashboard>(url, dashboard)).pipe(map(res => {
            return new Dashboard(res);
        }));
    }
}
