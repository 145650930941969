<app-widget-toolbar [widgetConfig]="widgetConfig"></app-widget-toolbar>
<div class="map-container">
    <div class="map-frame">
        <div id="map" leaflet
             [leafletOptions]="options"
            (leafletMapReady)="onMapReady($event)">
            <app-fullscreen-control [map]="map"></app-fullscreen-control>
        </div>
        <div class="heatmap-select">
        <p>Heatmapa:</p>
        <div *ngFor="let heatmapConfig of heatmapService.heatmapConfigs">
            <input type="radio" id="heatmap-{{heatmapConfig.id}}-{{widgetConfig.id}}"
                   (change)="heatmapService.onHeatmapSelected(heatmapConfig, map)"
                   name="heatmapSelect{{this.widgetConfig.id}}"
                   value="heatmap-{{heatmapConfig.id}}-{{widgetConfig.id}}"
                    [checked]="heatmapConfig.checked"
            >
            <label for="heatmap-{{heatmapConfig.id}}-{{widgetConfig.id}}">
                {{heatmapConfig.readableName}}</label>
        </div>
            </div>
    </div>
    <div *ngIf="measurements.length === 0 && !genericWidgetService.loading" class="no-data"><p>Brak danych z tego okresu!</p></div>
</div>
<app-mat-spinner-overlay *ngIf="genericWidgetService.loading" overlay="true"></app-mat-spinner-overlay>
