<mat-card>
	<mat-card-title>Ustawienia Użytkownika</mat-card-title>
	<mat-card-content>
		<mat-form-field>
			<mat-label>Imię</mat-label>
			<input matInput placeholder="Imię..." [value]="userAccount.first_name" (input)="userAccountFirstNameChange($event)">
		</mat-form-field>
		<mat-form-field>
			<mat-label>Nazwisko</mat-label>
			<input matInput placeholder="Nazwisko..." [value]="userAccount.last_name"
				   (input)="userAccountLastNameChange($event)">
		</mat-form-field>
		<button [disabled]="!enableUserEditButton" (click)="saveUser()">Zapisz</button>
        <button class="delete" (click)="openDeletePopup()">&times; Usuń konto</button>
	</mat-card-content>
</mat-card>

<mat-card class="mat-card-tabs">
	<mat-tab-group>
		<mat-tab label="Lista organizacji">
			<app-user-organization-settings [userAccount]="userAccount"></app-user-organization-settings>
		</mat-tab>
		<mat-tab label="Oczekujące prośby">
			<app-user-applications [userAccount]="userAccount"></app-user-applications>
		</mat-tab>
		<mat-tab label="Zaproszenia">
			<app-user-invites [userAccount]="userAccount"></app-user-invites>
		</mat-tab>
	</mat-tab-group>
</mat-card>

<button class="back-button" (click)="router.navigate(['dashboard'])">Wstecz</button>
