import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UserOrganization} from './components/organization/model/user-organization';

@Injectable({
    providedIn: 'root'
})
export class UserOrganizationUpdateService {

    private subject = new Subject<UserOrganization>();

    public emit(userOrganization: UserOrganization): void{
        this.subject.next(userOrganization);
    }

    public observable(): Observable<UserOrganization> {
        return this.subject.asObservable();
    }

    constructor() { }
}
