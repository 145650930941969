<mat-card class="mat-card-tabs">
    <mat-tab-group>
        <mat-tab label="Członkowie">
            <mat-table [dataSource]="userOrganizations" *ngIf="userOrganizations" class="mat-effects">

                <ng-container matColumnDef="user">
                    <mat-header-cell *matHeaderCellDef >Użytkownik</mat-header-cell>
                    <mat-cell *matCellDef="let userOrganization"> {{ userOrganization.user_data.email }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="permissions">
                    <mat-header-cell *matHeaderCellDef >Uprawnienia</mat-header-cell>
                    <mat-cell *matCellDef="let userOrganization">
                        <mat-select [disabled]="isSelf(userOrganization)" [(value)]="userOrganization.selectedPermission" (selectionChange)="updateUserPermissions(userOrganization, $event)">
                            <mat-option *ngFor="let permission of availablePermissions" [value]="permission">
                                {{permission.name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="justify-content-end">Akcje</mat-header-cell>
                    <mat-cell *matCellDef="let userOrganization" class="justify-content-end">
                        <button [disabled]="isSelf(userOrganization)" (click)="removeUserOrganization(userOrganization)">Usuń użytkownika z organizacji</button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: false"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
            </mat-table>

        </mat-tab>
        <mat-tab label="Prośby o dołączenie">
            <app-organization-applications [organization]="organization"></app-organization-applications>
        </mat-tab>
        <mat-tab label="Oczekujące zaproszenia">
            <app-organization-invites [organization]="organization"></app-organization-invites>
        </mat-tab>
    </mat-tab-group>
</mat-card>