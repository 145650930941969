import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Device} from '../../../models/device';
import {MatSelectChange} from '@angular/material/select';
import {DeviceBackendService} from '../../../services-backend/device-backend.service';
import {Organization} from '../model/organization';
import {MatTable} from '@angular/material/table';
import {AlertService} from '../../../services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-organization-devices',
    templateUrl: './organization-devices.component.html',
    styleUrls: ['./organization-devices.component.scss']
})
export class OrganizationDevicesComponent implements OnInit {
    columnsToDisplay = ['name', 'model_id', 'description', 'public', 'actions'];
    devices: Device[] = [];
    public organization: Organization;
    // @Input() organization: Organization;
    @ViewChild(MatTable) matPositions: MatTable<any>;

    constructor(
        private route: ActivatedRoute,
        private deviceBackendService: DeviceBackendService,
        private alertService: AlertService,
    ) { }

    ngOnInit(): void {
        this.route.parent.data.subscribe((data: { organization: Organization }) => {
            this.organization = data.organization;
            this.refreshData();
        });
    }

    private refreshData(): void {
        this.deviceBackendService.getByOrganizationId(this.organization.id)
            .subscribe(ret => {
                this.devices.length = 0;
                this.devices.push(...ret);
                this.matPositions.renderRows();
            });
    }

    public updateDeviceName(device: Device, event: Event): void {
        device.name = (event.target as HTMLInputElement).value;
        this.deviceBackendService.updateDevice(device)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano urządzenie');
            });
    }

    public updateDeviceDescription(device: Device, event: Event): void {
        device.description = (event.target as HTMLInputElement).value;
        this.deviceBackendService.updateDevice(device)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano urządzenie');
            });
    }

    public updatePublic(device: Device, event: MatSelectChange): void {
        device.public = event.value;
        this.deviceBackendService.updateDevice(device)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano urządzenie');
            });
    }

    public deleteDevice(device: Device): void {
        this.deviceBackendService.deleteDevice(device.id)
        .subscribe(ret => {
            const index = this.devices.indexOf(device);
            if (index !== -1){
                this.devices.splice(index, 1);
                this.matPositions.renderRows();
            }
            this.alertService.success('Usunięto urządzenie');
        });
    }
}
