/* tslint:disable:variable-name */

export class IUserOrganization {
    id: number;
    organization: number;
    user: number;
    permissions: Array<{permission: 'read_all' | 'admin'}>;
    user_data: { email: string };
}

export class IWriteUserOrganization {
    organization: number;
    user: number;
    permissions: Array<{permission: 'read_all' | 'admin' }>;
}


export class UserOrganization implements IUserOrganization{
    id: number;
    organization: number;
    permissions: Array<{ permission: 'read_all' | 'admin' }>;
    user: number;
    user_data: { email: string };

    selectedPermission: {code: 'read_all' | 'admin', name: string} = null;

    constructor(id: number, organization: number, permissions: Array<{ permission: 'read_all' | 'admin' }>,
                user: number, user_data: { email: string }) {
        this.id = id;
        this.organization = organization;
        this.permissions = permissions;
        this.user = user;
        this.user_data = user_data;
    }
}

export class UserOrganizationFactory {
    public static fromBackend(userOrganization: IUserOrganization): UserOrganization {
        return new UserOrganization(userOrganization.id, userOrganization.organization, userOrganization.permissions,
            userOrganization.user, userOrganization.user_data);
    }

    public static toBackend(userOrganization: UserOrganization): IWriteUserOrganization {
        return {
            organization: userOrganization.organization,
            permissions: userOrganization.permissions,
            user: userOrganization.user,
        };
    }
}
