import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {DateService} from '../services-primitive/date.service';
import {EventEmitter, Injectable} from '@angular/core';
import {Measurement} from '../models/measurement';
import {MatSelectChange} from '@angular/material/select';

export interface PlayerConfigSpace {
    start: Date;
    end: Date;
}

export interface PlayerConfig {
    minDate: Date;
    currentDate: Date;
    maxDate: Date;
    snakeLength: number;
    speed: number;
    spaces: PlayerConfigSpace[];
    paused: boolean;
    playing: boolean;
}

export interface PlayerFrame {
    date: Date | null;
}

@Injectable()
export class PlayerService {
    private readonly snakeLengthModifier: number = 2 * 0.001;


    constructor() { }
    private readonly minimalBreakCorrection = 4;

    private readonly fps = 60;
    private readonly basicAnimationSpeed = 1;
    private readonly basicSnakeLength = undefined;

    private playerConfig: PlayerConfig = {
        maxDate: undefined,
        currentDate: undefined,
        minDate: undefined,
        snakeLength: this.basicSnakeLength,
        speed: this.basicAnimationSpeed,
        spaces: [],
        paused: false,
        playing: false,
    };
    private frameSubject = new BehaviorSubject<PlayerFrame>({date: null});
    private configSubject: BehaviorSubject<PlayerConfig> = new BehaviorSubject<Readonly<PlayerConfig>>(this.playerConfig);
    private timeSubscription: Subscription;
    private readonly animationTotalTimeInSeconds: number = 60;

    hasData = false;
    private realTimeWithoutBreaks: number;

    private static sortSpaces(spaces: PlayerConfigSpace[]): void {
        const sortedSpaces = spaces.sort((el1: PlayerConfigSpace, el2: PlayerConfigSpace) => {
            if (el1.start < el2.start){
                return -1;
            }
            else if (el1.start > el2.start){
                return 1;
            }
            else {
                return 0;
            }
        });

        const newSpaces: PlayerConfigSpace[] = [];
        let lastSpaceEnd: Date;
        for (const space of sortedSpaces) {
            // noinspection JSUnusedAssignment
            if (lastSpaceEnd === undefined || space.start > lastSpaceEnd){
                newSpaces.push(space);
            }
            else {
                newSpaces[newSpaces.length - 1].end = space.end;
            }
            lastSpaceEnd = newSpaces[newSpaces.length - 1].end;
        }
        spaces.length = 0;
        spaces.push(...newSpaces);
    }

    private static sumSpaces(spaces: PlayerConfigSpace[]): number {
        let sum = 0;
        for (const space of spaces){
            sum += (space.end.getTime() - space.start.getTime());
        }
        return sum;
    }

    private static joinSpaces(spaces1: PlayerConfigSpace[], spaces2: PlayerConfigSpace[]): PlayerConfigSpace[] {
        const ret: PlayerConfigSpace[] = [];
        ret.push(...spaces1);
        ret.push(...spaces2);
        PlayerService.sortSpaces(ret);
        return ret;
    }

    public getConfig(): Readonly<PlayerConfig> {
        return this.playerConfig;
    }

    public pause(): void {

        this.playerConfig.paused = true;
        this.playerConfig.playing = true;
        if (this.timeSubscription){
            this.timeSubscription.unsubscribe();
            this.timeSubscription = null;
        }
        this.configSubject.next(this.playerConfig);
    }

    public resume(): void {
        this.playerConfig.paused = false;
        this.playerConfig.playing = true;
        if (this.timeSubscription){
            this.timeSubscription.unsubscribe();
            this.timeSubscription = null;
        }
        this.timeSubscription = interval(1000 / this.fps).subscribe((_) => { this.showOneFrame(); });
        this.configSubject.next(this.playerConfig);
    }

    public stop(): void {
        this.playerConfig.paused = false;
        this.playerConfig.playing = false;
        this.playerConfig.currentDate = this.playerConfig.minDate;
        if (this.timeSubscription){
            this.timeSubscription.unsubscribe();
            this.timeSubscription = null;
        }
        this.configSubject.next(this.playerConfig);
        this.frameSubject.next({date: null});
    }

    public reset(): void {
        this.hasData = false;
        if (this.timeSubscription){
            this.timeSubscription.unsubscribe();
            this.timeSubscription = null;
        }
        this.playerConfig.paused = false;
        this.playerConfig.playing = false;
        this.playerConfig.minDate = undefined;
        this.playerConfig.maxDate = undefined;
        this.playerConfig.currentDate = undefined;
        this.playerConfig.spaces.length = 0;
        this.configSubject.next(this.playerConfig);


    }

    public getSnakeStart(): Date {
        if (!this.playerConfig.snakeLength){
            return this.playerConfig.minDate;
        }
        return new Date(this.playerConfig.currentDate.getTime() -
            this.playerConfig.snakeLength * this.realTimeWithoutBreaks * this.snakeLengthModifier);
    }

    canShowFrame(fromTime: number, toTime: number): boolean {
        let i = 0;
        const length = this.playerConfig.spaces.length;

        for (i; i < length; i++) {
            const breakStart = this.playerConfig.spaces[i].start.getTime();
            const breakEnd = this.playerConfig.spaces[i].end.getTime();
            if (fromTime > breakStart && toTime < breakEnd) {
                return false;
            }
        }
        return true;
    }

    private showOneFrame(): void {
        if (this.playerConfig.currentDate.getTime() + this.getRealTimeFrame() <= this.playerConfig.maxDate.getTime()) {

            this.playerConfig.currentDate = new Date(this.playerConfig.currentDate.getTime() + this.getRealTimeFrame());

            while (!this.canShowFrame(this.playerConfig.currentDate.getTime(),
                this.playerConfig.currentDate.getTime() + this.getRealTimeFrame())) {
                this.playerConfig.currentDate = new Date(this.playerConfig.currentDate.getTime() + this.getRealTimeFrame());
            }
            this.frameSubject.next({date: this.playerConfig.currentDate});
        } else {
            console.log('end animation');
            this.stop();
        }
    }

    public getCurrentDateString(): string {
        return DateService.dateToString(this.playerConfig.currentDate || new Date());
    }

    setSnakeLength(event: MatSelectChange): void {
        this.playerConfig.snakeLength = parseInt(event.value, 10);
        this.configSubject.next(this.playerConfig);
    }

    setAnimationSpeed(event: MatSelectChange): void {
        const animationSpeed = parseFloat(event.value);
        this.playerConfig.speed = this.basicAnimationSpeed * animationSpeed;
        console.log(this.getRealTimeFrame());
    }

    // assumes that measurements are sorted by date
    public appendMeasurements(measurements: Measurement[]): void {
        console.log('Appending measurements to player', measurements.length);
        if (measurements.length > 0) {
            if (this.playerConfig.minDate === undefined && this.playerConfig.maxDate === undefined) {
                this.playerConfig.minDate = measurements[0].measurementTime;
                this.playerConfig.currentDate = this.playerConfig.minDate;
                this.playerConfig.maxDate = measurements[measurements.length - 1].measurementTime;
                this.playerConfig.spaces.push({
                    start: this.playerConfig.minDate,
                    end: this.playerConfig.maxDate,
                });
            } else {
                if (this.playerConfig.minDate > measurements[0].measurementTime) {
                    const oldMinDate = this.playerConfig.minDate;
                    this.playerConfig.minDate = measurements[0].measurementTime;
                    this.playerConfig.currentDate = this.playerConfig.minDate;
                    this.playerConfig.spaces.push({
                        start: this.playerConfig.minDate,
                        end: oldMinDate,
                    });
                }
                if (this.playerConfig.maxDate < measurements[measurements.length - 1].measurementTime) {
                    const oldMaxDate = this.playerConfig.maxDate;
                    this.playerConfig.maxDate = measurements[measurements.length - 1].measurementTime;
                    this.playerConfig.spaces.push({
                        start: oldMaxDate,
                        end: this.playerConfig.maxDate,
                    });
                }
            }
            PlayerService.sortSpaces(this.playerConfig.spaces);
            const spaces = this.createSpaces(measurements);
            const joinedSpaces = PlayerService.joinSpaces(this.playerConfig.spaces, spaces);
            this.playerConfig.spaces.length = 0;
            this.playerConfig.spaces.push(...joinedSpaces);

            this.realTimeWithoutBreaks = (this.playerConfig.maxDate.getTime() - this.playerConfig.minDate.getTime())
                - PlayerService.sumSpaces(this.playerConfig.spaces);
            this.hasData = true;
            console.log('spaces', this.getConfig().spaces);
            console.log('real time without breaks', this.realTimeWithoutBreaks);
            console.log('real time frame', this.getRealTimeFrame());
            console.log('has data');
        }
    }

    public getRealTimeFrame(): number{
        return Math.floor((this.realTimeWithoutBreaks / (this.fps * this.animationTotalTimeInSeconds))) * this.playerConfig.speed;
    }

    private createSpaces(measurements: Measurement[]): PlayerConfigSpace[] {

        const spaces: PlayerConfigSpace[] = [];
        const minimalBreak = Math.max(
            this.minimalBreakCorrection *
            ((this.playerConfig.maxDate.getTime() - this.playerConfig.minDate.getTime()
            ) / (this.animationTotalTimeInSeconds * this.fps)),
            4000);

        if (measurements[0].measurementTime.getTime() - this.playerConfig.minDate.getTime() > minimalBreak) {
            spaces.push({start: this.playerConfig.minDate, end: measurements[0].measurementTime});
        }

        for (let i = 1; i < measurements.length; i++) {
            const measurementTime = measurements[i].measurementTime;
            const prevMeasurementTime = measurements[i - 1].measurementTime;

            if (measurementTime.getTime() - prevMeasurementTime.getTime() > minimalBreak) {
                console.log('found break');
                spaces.push({start: prevMeasurementTime, end: measurementTime});
            }
        }

        if (this.playerConfig.maxDate.getTime() - measurements[measurements.length - 1].measurementTime.getTime() > minimalBreak) {
            spaces.push({start: measurements[measurements.length - 1].measurementTime, end: this.playerConfig.maxDate});
        }
        return spaces;
    }

    public subscribeOnFrames(callback: ((notification: {date: Date | null}) => void)): Subscription {
        return this.frameSubject.asObservable().subscribe(notification => callback(notification));
    }

    public subscribeOnConfigChange(callback: ((notification: PlayerConfig) => void)): Subscription {
        return this.configSubject.asObservable().subscribe(notification => callback(notification));
    }

    onSeekbarClick(event: Event): void {
        if (this.playerConfig.playing) {
            const limit = parseInt((event.target as HTMLInputElement).value, 10);
            this.playerConfig.currentDate = new Date(limit);
            this.showOneFrame();
        }
    }
}
