import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ProfileResolverService} from '../resolvers/profile-resolver.service';


export interface DateConfig {
    measurement_duration: number;
    measurement_start: Date;
    most_recent_measurements: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class GlobalDateService {

    private subject: BehaviorSubject<DateConfig>;

    constructor(private profileResolverService: ProfileResolverService) {
        const profile = profileResolverService.profile;
        this.subject = new BehaviorSubject<DateConfig>({
            measurement_duration: profile.measurement_duration,
            measurement_start: profile.measurement_start,
            most_recent_measurements: profile.most_recent_measurements,
        });
    }

    public observable(): Observable<DateConfig> {
        return this.subject.asObservable();
    }

    public emit(config: DateConfig): void{
        this.subject.next(config);
    }

    public currentDateConfig(): DateConfig {
        return this.subject.getValue();
    }
}
