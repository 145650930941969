import {DateService} from '../../services-primitive/date.service';

export class HeatmapConfig {
    id: number;
    readableName: string;
    name: string;
    sensorType: number;
    sensorTypeName: string;
    minLatitude: number;
    maxLatitude: number;
    minLongitude: number;
    maxLongitude: number;
    intervalInMinutes: number;
    granularity: number;
    lastHeatmapTimestamp: Date;
    organization: number;
    public: boolean;
    checked: boolean;


    constructor(id: number, readableName: string, name: string, sensorType: number, sensorTypeName: string,
                minLatitude: number, maxLatitude: number, minLongitude: number, maxLongitude: number,
                intervalInMinutes: number, granularity: number, lastHeatmapTimestamp: Date, organization: number,
                isPublic: boolean, checked: boolean) {
        this.id = id;
        this.readableName = readableName;
        this.name = name;
        this.sensorType = sensorType;
        this.sensorTypeName = sensorTypeName;
        this.minLatitude = minLatitude;
        this.maxLatitude = maxLatitude;
        this.minLongitude = minLongitude;
        this.maxLongitude = maxLongitude;
        this.intervalInMinutes = intervalInMinutes;
        this.granularity = granularity;
        this.lastHeatmapTimestamp = lastHeatmapTimestamp;
        this.organization = organization;
        this.public = isPublic;
        this.checked = checked;
    }
}


export interface IHeatmapConfig {
    id: number;
    readable_name: string;
    name: string;
    sensor_type: number;
    sensor_type_name: string;
    min_latitude: string;
    max_latitude: string;
    min_longitude: string;
    max_longitude: string;
    interval_in_minutes: number;
    granularity: number;
    last_heatmap_timestamp: string;
    organization: number;
    public: boolean;
}

export class HeatmapConfigFactory {
    public static fromBackend(h: IHeatmapConfig): HeatmapConfig {
        return new HeatmapConfig(
            h.id,
            h.readable_name,
            h.name,
            h.sensor_type,
            h.sensor_type_name,
            parseFloat(h.min_latitude),
            parseFloat(h.max_latitude),
            parseFloat(h.min_longitude),
            parseFloat(h.max_longitude),
            h.interval_in_minutes,
            h.granularity,
            DateService.stringDateToDate(h.last_heatmap_timestamp),
            h.organization,
            h.public,
            false,
        );
    }

    public static empty(): HeatmapConfig {
        return new HeatmapConfig(
            -1,
            'Brak',
            'none',
            -1,
            '',
            0,
            0,
            0,
            0,
            0,
            0,
            new Date(),
            -1,
            true,
            true,
        );
    }
}
