import * as L from 'leaflet';
import {SensorType} from './device-sensor';
import {DateService} from '../services-primitive/date.service';


export class MarkerMeasurementValue {
    value: number;
    sensorType: SensorType;
    deviceSensorId: number;

    constructor(value: number, sensorType: SensorType, deviceSensorId: number) {
        this.value = value;
        this.sensorType = sensorType;
        this.deviceSensorId = deviceSensorId;
    }
}

export class MarkerMeasurementDate {

    constructor(date: Date, values: MarkerMeasurementValue[]) {
        this.date = date;
        this.values = values;
    }

    date: Date;
    values: MarkerMeasurementValue [];
}

export class MeasurementMarker {


    constructor(markerShape: number, latitude: number, longitude: number, decidingValue: number,
                decidingSensorId: number, decidingSensorType: SensorType, decidingDate: Date,
                measurements: MarkerMeasurementDate[]) {
        this.markerShape = markerShape;
        this.latitude = latitude;
        this.longitude = longitude;
        this.decidingValue = decidingValue;
        this.decidingSensorId = decidingSensorId;
        this.decidingSensorType = decidingSensorType;
        this.decidingDate = decidingDate;
        this.measurements = measurements;
    }

    markerShape: number;
    latitude: number;
    longitude: number;
    decidingValue: number;
    decidingSensorId: number;
    decidingSensorType?: SensorType;
    decidingDate: Date;
    measurements: MarkerMeasurementDate[] = [];

    getLatLng(): L.LatLng {
        return L.latLng(this.latitude, this.longitude);
    }

    getPopupString(): string {
        let ret = '';
        for (const measurement of this.measurements) {
            ret += `<div>Data: ${DateService.dateToString(measurement.date)}</div>`;
            for (const value of measurement.values) {
                ret += (`<div>${ value.sensorType.code }: ${ value.value } ${ value.sensorType.units }</div>`);
            }
        }
        return ret;
    }


    getConditionsString(): string {

        const value = this.decidingValue;
        let condition = 'good';
        if (this.decidingSensorType) {
            if (this.decidingSensorType.code === 'pm10') { // 61 = pm10_mobile
                if (value >= 0 && value < 54) {
                    condition = 'good';
                } else if (value >= 54 && value < 154) {
                    condition = 'moderate';
                } else if (value >= 154 && value < 254) {
                    condition = 'unhealthy_fsg';
                } else if (value >= 254 && value < 354) {
                    condition = 'unhealthy';
                } else if (value >= 354 && value < 424) {
                    condition = 'very_unhealthy';
                } else if (value >= 424) {
                    condition = 'hazardous';
                }
            } else if (this.decidingSensorType.code === 'pm25') { // 62 = pm25_mobile
                if (value >= 0 && value < 12.1) {
                    condition = 'good';
                } else if (value >= 12.1 && value < 35.4) {
                    condition = 'moderate';
                } else if (value >= 35.4 && value < 55.4) {
                    condition = 'unhealthy_fsg';
                } else if (value >= 55.4 && value < 150.4) {
                    condition = 'unhealthy';
                } else if (value >= 150.4 && value < 250.4) {
                    condition = 'very_unhealthy';
                } else if (value >= 250.4) {
                    condition = 'hazardous';
                }
            } else if (this.decidingSensorType.code === 'pm1') { // 63 = pm1_mobile
                if (value >= 0 && value < 12.1) {
                    condition = 'good';
                } else if (value >= 12.1 && value < 35.4) {
                    condition = 'moderate';
                } else if (value >= 35.4 && value < 55.4) {
                    condition = 'unhealthy_fsg';
                } else if (value >= 55.4 && value < 150.4) {
                    condition = 'unhealthy';
                } else if (value >= 150.4 && value < 250.4) {
                    condition = 'very_unhealthy';
                } else if (value >= 250.4) {
                    condition = 'hazardous';
                }
            } else {
                // TODO set unspecified
                condition = 'good';
            }
        }
        return condition;
    }
}
