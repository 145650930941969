import {SensorType} from './device-sensor';

export class TableMeasurement {
    value: number;

    constructor(value: number) {
        this.value = value;
    }
}

export class TableMeasurementRow {
    date: Date;
    latitude: number;
    longitude: number;
    // deviceSensorId -> TableMeasurement
    measurements: Map<number, TableMeasurement>;


    constructor(date: Date, latitude: number, longitude: number, measurements: Map<number, TableMeasurement>) {
        this.date = date;
        this.latitude = latitude;
        this.longitude = longitude;
        this.measurements = measurements;
    }
}


export class MeasurementTable {
    deviceSensorIds: Map<number, SensorType> = new Map<number, SensorType>();
    rows: TableMeasurementRow [];


    constructor(deviceSensorIds: Map<number, SensorType>, rows: TableMeasurementRow[]) {
        this.deviceSensorIds = deviceSensorIds;
        this.rows = rows;
    }
}
