import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardBackendService} from '../../services-backend/dashboard-backend.service';
import {AlertService} from '../../services/alert.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-dashboard-create',
    templateUrl: './dashboard-create.component.html',
    styleUrls: ['./dashboard-create.component.scss']
})
export class DashboardCreateComponent implements OnInit {
    public loading = false;

    constructor(
        public router: Router,
        private matDialog: MatDialog,
        private dashboardBackendService: DashboardBackendService,
        private alertService: AlertService,
    ) { }

    ngOnInit(): void {
    }

    public createDashboard(form: FormGroup): void {
        this.loading = true;
        const name = form.controls['dashboard-name'].value;
        this.dashboardBackendService.createDashboard({name})
            .subscribe(dashboard => {
                this.router.navigate(['/dashboard', dashboard.id]).then(() => {
                    this.matDialog.closeAll();
                    this.loading = false;
                });
            });

    }
}
