import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {ProfileBackendService} from '../services-backend/profile-backend.service';
import {UserProfile} from '../models/user-profile';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileResolverService implements Resolve<UserProfile> {

    public profile: UserProfile;

    constructor(private profileBackendService: ProfileBackendService) {
    }

    resolve(): Observable<UserProfile> | UserProfile {
        if (this.profile){
            return this.profile;
        }
        else {
            return this.profileBackendService.getProfile().pipe(map(profile => {
                this.profile = profile;
                return profile;
            }));
        }
    }

    public refreshProfile(): void {
        this.profileBackendService.getProfile().pipe(map(profile => {
                this.profile = profile;
                return profile;
            })).subscribe(_ => {});
    }
}
