import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as config } from '../../environments/environment';
import * as L from 'leaflet';
import {Icon, Marker} from 'leaflet';
import {IMeasurement, Measurement} from '../models/measurement';
import {DeviceSensor} from '../models/device-sensor';

@Injectable({
    providedIn: 'root'
})
export class MarkerService {

    constructor(private http: HttpClient) { }

    static getIcon(type, shape, className): Icon {
        return new L.Icon({
            iconUrl: 'assets/images/icons/' + shape + '/' + type + '.png',
            iconSize: [30, 30],
            // shadowUrl: 'assets/images/icons/shadow.png',
            // shadowSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -34],
            className: className + ' leaf-marker'
        });
    }

    // getMarkers(id, start, end): Observable<Measurement[]> {
    //     return this.http.get<IMeasurement[]>
    //     (`${config.url}/measurements/?page_size=5000&ordering=-measurement_time&device_sensor_ids=` + id + `&sensor_data_start=` + start + `&sensor_data_end=` + end)
    //         .pipe(map((res: any) => res.results.map(data => new Measurement.fromJson(data))));
    // }

    getDeviceName(id): Observable<DeviceSensor[]> {
        const device = this.http.get<DeviceSensor[]>
        (`${config.url}/devices/?include_public=True&include_sensors=True&ids=` + id)
            .pipe(map((res: any) => res[0].sensors.map(data => new DeviceSensor(data.id, data.sensor_type))));

        // @ts-ignore
        return device.name;
    }

    getPollutionLevel(value, sensor): string {
        if (sensor === 'pm10_mobile') {
            if (value >= 0 && value < 54) {
                return 'good';
            } else if (value >= 54 && value < 154) {
                return 'moderate';
            } else if (value >= 154 && value < 254) {
                return 'unhealthy_fsg';
            } else if (value >= 254 && value < 354) {
                return 'unhealthy';
            } else if (value >= 354 && value < 424) {
                return 'very_unhealthy';
            } else if (value >= 424) {
                return 'hazardous';
            }
        } else if (sensor === 'pm25_mobile' || sensor === 'pm1_mobile') {
            if (value >= 0 && value < 12.1) {
                return 'good';
            } else if (parseFloat(value) >= 12.1 && parseFloat(value) < 35.4) {
                return 'moderate';
            } else if (parseFloat(value) >= 35.4 && parseFloat(value) < 55.4) {
                return 'unhealthy_fsg';
            } else if (parseFloat(value) >= 55.4 && parseFloat(value) < 150.4) {
                return 'unhealthy';
            } else if (parseFloat(value) >= 150.4 && parseFloat(value) < 250.4) {
                return 'very_unhealthy';
            } else if (parseFloat(value) >= 250.4) {
                return 'hazardous';
            }
        } else {
            console.log('pollution level exception\n' +
                'value: ' + value + '\n' +
                'sensor: ' + sensor);
        }
    }

    createMarker(sensorType, lat, lon, value, shape, className): Marker {
        const icon = MarkerService.getIcon(this.getPollutionLevel(value, sensorType), shape, className);
        return L.marker([lat, lon], {icon});
    }
}
