import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {ProfileBackendService} from '../services-backend/profile-backend.service';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserAccount} from '../models/user-account';

@Injectable({
    providedIn: 'root'
})
export class UserAccountResolverService implements Resolve<UserAccount> {

    private account: UserAccount;
    private accountSubject: ReplaySubject<UserAccount> = new ReplaySubject<UserAccount>();

    constructor(private profileBackendService: ProfileBackendService) {
    }

    resolve(): Observable<UserAccount> | UserAccount {
        if (this.account){
            return this.account;
        }
        else {
            return this.profileBackendService.getUserAccount().pipe(map(account => {
                this.account = account;
                this.accountSubject.next(account);
                return account;
            }));
        }
    }

    public observable(): Observable<UserAccount> {
        return this.accountSubject.asObservable();
    }
}
