import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmationData {
    title: string;
    description: string;
    positiveMessage: string;
    negativeMessage: string;
}

@Component({
    selector: 'app-popup-confirmation',
    templateUrl: './popup-confirmation.component.html',
    styleUrls: ['./popup-confirmation.component.scss']
})
export class PopupConfirmationComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<PopupConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
    ) {}

    ngOnInit(): void {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
