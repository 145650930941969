import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserApplication} from '../model/user-application';
import {OrganizationBackendService} from '../organization-backend.service';
import {Organization} from '../model/organization';
import {MatTable} from '@angular/material/table';
import {AlertService} from '../../../services/alert.service';
import {UserOrganizationUpdateService} from '../../../user-organization-update.service';

@Component({
    selector: 'app-organization-applications',
    templateUrl: './organization-applications.component.html',
    styleUrls: ['./organization-applications.component.scss']
})
export class OrganizationApplicationsComponent implements OnInit {

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private userOrganizationUpdateService: UserOrganizationUpdateService,
    ) { }

    public userApplications: UserApplication[] = [];

    @Input() organization: Organization;
    @ViewChild(MatTable) matPositions: MatTable<any>;
    columnsToDisplay: string[] = ['user', 'actions'];

    private static deleteFromArray<T>(array: T[], object: T): void{
        const index = array.indexOf(object);
        if (index !== -1){
            array.splice(index, 1);
        }
    }

    ngOnInit(): void {
        this.refreshData();
    }

    private refreshData(): void {
        this.organizationBackendService.getAllUserApplications(this.organization.id).subscribe(ret => {
            this.userApplications.length = 0;
            this.userApplications.push(...ret);
            this.matPositions.renderRows();
        });
    }

    acceptApplication(userApplication: UserApplication): void {
        this.organizationBackendService.acceptApplication(userApplication).subscribe(ret => {
            this.alertService.success('Aplikacja została zaakceptowana');
            OrganizationApplicationsComponent.deleteFromArray(this.userApplications, userApplication);
            this.userOrganizationUpdateService.emit(ret);
            this.matPositions.renderRows();
        });
    }

    denyApplication(userApplication: UserApplication): void {
        this.organizationBackendService.denyApplication(userApplication).subscribe(_ => {
            this.alertService.success('Aplikacja została odrzucona');
            OrganizationApplicationsComponent.deleteFromArray(this.userApplications, userApplication);
            this.matPositions.renderRows();
        });
    }
}
