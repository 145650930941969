import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    constructor() {
    }

    public static dateSkipTime(date: Date): Date {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public static stringDateToDate(s: string): Date {
        if (s === '' || s == null) {
            return null;
        }
        return new Date(s);
    }

    public static stringDateToDateSkipTime(s: string): Date {
        if (s === '' || s == null) {
            return null;
        }
        return this.dateSkipTime(new Date(s));
    }

    // 2020-08-31 08:38
    public static dateToString(date: Date): string {
        const spacer = '-';
        let s = '';
        date = DateService.roundSeconds(date);
        s += date.getFullYear() + spacer;
        s += DateService.padNumber((date.getMonth() + 1), 2) + spacer;
        s += DateService.padNumber(date.getDate(), 2) + ' ';
        s += DateService.padNumber(date.getHours(), 2) + ':';
        s += DateService.padNumber(date.getMinutes(), 2);
        return s;
    }

    // 08:38
    public static dateToStringTime(date: Date): string {
        const spacer = ':';
        let s = '';
        date = DateService.roundSeconds(date);
        s += DateService.padNumber(date.getHours(), 2) + spacer;
        s += DateService.padNumber(date.getMinutes(), 2);
        return s;
    }

    // 2020-08-31
    public static dateToStringDate(date: Date): string {
        if (date){
            const spacer = '-';
            let s = '';
            s += date.getFullYear() + spacer;
            s += DateService.padNumber((date.getMonth() + 1), 2) + spacer;
            s += DateService.padNumber(date.getDate(), 2);
            return s;
        }else{
            return null;
        }

    }

    public static getCurrentYear(): number {
        const date = new Date();
        return date.getFullYear();
    }

    public static getCurrentMonth(): number {
        const date = new Date();
        return date.getMonth();
    }

    public static differenceToString(dif: number): string {
        const spacer = ':';
        let s = '';
        dif = dif - (dif % 1000);
        if ((dif % 60000) / 1000 > 30) {
            dif -= (dif % 60000) / 1000;
            dif += 60000;
        }
        s += DateService.padNumber((dif - (dif % 3600000)) / 3600000, 2) + spacer;
        s += DateService.padNumber((dif % 3600000) / 60000, 2);
        return s;
    }

    public static getTimeFromSec(sec: number): string {
        const h = Math.floor(sec / 3600);
        const m = Math.floor(sec % 3600 / 60);
        return h + 'h ' + m + 'm';
    }

    public static getTime(date): any {
        if (date) {
            const today = new Date(date);
            const hour = today.getHours();
            let minutes = today.getMinutes();
            const seconds = today.getSeconds();
            if (seconds >= 30) {
                minutes = minutes + 1;
            }
            console.log(date);
            return hour + ':' + minutes;
        }
    }

    private static roundSeconds(date: Date): Date {
        if (date.getSeconds() > 30) {
            return new Date(date.getTime() - date.getSeconds() * 1000 + 60 * 1000);
        }
        return date;
    }

    static padNumber(x: number, size: number): string {
        let s = x + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }
}
