import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UserOrganization} from '../organization/model/user-organization';
import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {UserAccount} from '../../models/user-account';
import {OrganizationBackendService} from '../organization/organization-backend.service';
import {UserAccountResolverService} from '../../resolvers/user-account-resolver.service';

@Injectable({
    providedIn: 'root'
})
export class UserOrganizationResolverService implements Resolve<UserOrganization[]> {

    constructor(private organizationService: OrganizationBackendService,
                private userAccountResolver: UserAccountResolverService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserOrganization[]> {
        return this.userAccountResolver.observable().pipe(take(1), mergeMap((userAccount: UserAccount) => {
            return this.organizationService.getAllUserOrganizationsForUser(userAccount.id);
        }));
    }
}
