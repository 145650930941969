/* tslint:disable:variable-name */
export interface IUserProfile {
    most_recent_measurements: boolean;
    measurement_start: Date;
    measurement_duration: number;
    first_name: string;
    last_name: string;
    current_dashboard: number;
}

export class UserProfile implements IUserProfile {
    current_dashboard: number;
    first_name: string;
    last_name: string;
    measurement_duration: number;
    measurement_start: Date;
    most_recent_measurements: boolean;

    constructor(userProfile: IUserProfile) {
        this.current_dashboard = userProfile.current_dashboard;
        this.first_name = userProfile.first_name;
        this.last_name = userProfile.last_name;
        this.measurement_duration = userProfile.measurement_duration;
        this.measurement_start = new Date(userProfile.measurement_start);
        this.most_recent_measurements = userProfile.most_recent_measurements;
    }

    getFullName(): string{
        if (!this.first_name && !this.last_name){
            return '';
        }
        return `${this.first_name} ${this.last_name}`;
    }

    getMeasurementEnd(): Date {
        return new Date(this.measurement_start.getTime() + this.measurement_duration * 1000);
    }
}
