<div class="seek-bar">
        <button (click)="playerService.resume()" *ngIf="!playerService.getConfig().playing || playerService.getConfig().paused" class="button play"></button>
        <button (click)="playerService.pause()" *ngIf="!playerService.getConfig().paused && this.playerService.getConfig().playing"
                class="button pause"></button>
        <button (click)="playerService.stop()" class="button stop"></button>
        <div class="timeline">
            <!--<input type="range" min="{{ playerService.startTime }}" max="{{ playerService.endTime }}" value="{{ playerService.seekBarValue }}" step="{{ playerService.realTimeFrame }}" (input)="playerService.hideFrom($event)" />-->
            <div class="timeline-slider" *ngIf="playerService.hasData">
                <input id="time-range" type="range" min="{{ playerService.getConfig().minDate.getTime() }}"
                       max="{{ playerService.getConfig().maxDate.getTime() }}" value="{{ playerService.getConfig().currentDate.getTime() }}"
                       step="{{ playerService.getRealTimeFrame() }}"
                       (input)="playerService.onSeekbarClick($event)"
                />

<!--                       (change)="playerService.colorRange()" -->

                <span id="colored-slider"></span>
            </div>
            <div *ngIf="playerService.hasData" class="time">{{ playerService.getCurrentDateString() }}</div>
        </div>

        <!--<select (change)="playerService.setSnakeLength($event)">-->
            <!--<option value="0">Wybierz długość animacji</option>-->
            <!--<option value="10">Krótki</option>-->
            <!--<option value="25">Średni</option>-->
            <!--<option value="50">Długi</option>-->
            <!--<option value="100">Bardzo długi</option>-->
        <!--</select>-->

        <mat-form-field>
            <mat-label>Długość</mat-label>
            <mat-select (selectionChange)="playerService.setSnakeLength($event)">
                <mat-option [disabled]>Wybierz długość animacji</mat-option>
                <mat-option value="10">Krótki</mat-option>
                <mat-option value="25">Średni</mat-option>
                <mat-option value="50">Długi</mat-option>
                <mat-option value="100">Bardzo długi</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Szybkość</mat-label>
            <mat-select (selectionChange)="playerService.setAnimationSpeed($event)">
                <mat-option [disabled]>Wybierz szybkość animacji</mat-option>
                <mat-option value="4">x4</mat-option>
                <mat-option value="2">x2</mat-option>
                <mat-option value="1">x1</mat-option>
                <mat-option value="0.5">x0.5</mat-option>
                <mat-option value="0.25">x0.25</mat-option>
            </mat-select>
        </mat-form-field>

        <!--<select (change)="playerService.setAnimationSpeed($event)">-->
            <!--<option value="1">&#45;&#45; Wybierz szybkość animacji &#45;&#45;</option>-->
            <!--<option value="4">x4</option>-->
            <!--<option value="2">x2</option>-->
            <!--<option value="1">x1</option>-->
            <!--<option value="0.5">x0.5</option>-->
            <!--<option value="0.25">x0.25</option>-->
        <!--</select>-->
    </div>
