import {GridsterItem} from 'angular-gridster2';

export interface IWidget{
    id: number;
    display_type: 'chart' | 'plain' | 'map';
    height: number;
    width: number;
    name: string;
    devices: number[];
    device_sensors: number[];
    dashboard: number;
    main_sensor: number;
    position_x: number;
    position_y: number;
}

/* tslint:disable:variable-name */
export class Widget implements IWidget{
    id: number;
    display_type: 'chart' | 'plain' | 'map';
    height: number;
    width: number;
    name: string;
    devices: number[];
    device_sensors: number[];
    dashboard: number;
    main_sensor: number;

    gridsterItem: GridsterItem;


    constructor(widget: IWidget) {
        this.id = widget.id;
        this.dashboard = widget.dashboard;
        this.display_type = widget.display_type;
        this.height = widget.height;
        this.width = widget.width;
        this.name = widget.name;
        this.devices = widget.devices;
        this.device_sensors = widget.device_sensors;
        this.dashboard = widget.dashboard;
        this.main_sensor = widget.main_sensor || null;
        this.position_x = widget.position_x;
        this.position_y = widget.position_y;

        this.gridsterItem = {
            cols: this.width,
            rows: this.height,
            x: this.position_x,
            y: this.position_y,
            id: this.id,
        };
    }

    position_x: number;
    position_y: number;

}
