import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BackendErrorResponse} from '../models/backend-error.response';
import {AlertService} from './alert.service';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor(
        private alertService: AlertService
    ) { }

    public handleError(error: BackendErrorResponse, customMessages?: { [key: number]: string }): Observable<never>{
        if (customMessages && error.error_code in customMessages){
            this.alertService.error(customMessages[error.error_code]);
        } else if (error.error_code === 713) {
            this.alertService.error(`Uzytkownik należy już do organizacji`);
        } else if (error.error_code === 714) {
            this.alertService.error(`Najpierw usuń wszystkie urządzenia i użytkowników`);
        } else if (error.error_code === 715) {
            this.alertService.error(`Organizacja o tej nazwie już istnieje`);
        } else if (error.error_code === 716) {
            this.alertService.error(`Istnieje już prośba o dołączenie`);
        } else if (error.error_code === 715) {
            this.alertService.error(`Istnieje już zaproszenie`);
        } else if (error.error_code === 404) {
            this.alertService.error('Nie znaleziono');
        } else {
            this.alertService.error(`Błąd: ${error.description}`);
            return throwError('Something bad happened; please try again later.');
        }
    }

    public pipeThroughErrorHandler<T>(o: Observable<T>): Observable<T> {
        return o.pipe(catchError(error => this.handleError(error)));
    }
}
