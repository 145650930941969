import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainComponent } from './components/main/main.component';
import { DashboardNavigationComponent } from './components/dashboard-navigation/dashboard-navigation.component';
import { MapComponent } from './components/map/map.component';
import { TableComponent } from './components/table/table.component';
import { ChartComponent } from './components/chart/chart.component';
import { DashboardCreateComponent } from './components/dashboard-create/dashboard-create.component';
import { PlayerComponent } from './components/player/player.component';
import { GlobalDateComponent } from './components/global-date/global-date.component';
import { MqttComponent } from './components/mqtt/mqtt.component';
import { LoginComponent } from './components/login/login.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import {GridsterModule} from 'angular-gridster2';
import { AlertComponent } from './components/alert/alert.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {IMqttServiceOptions, MqttModule} from 'ngx-mqtt';
import { RegisterComponent } from './components/register/register.component';
import { OrganizationSettingsComponent } from './components/organization/organization-settings/organization-settings.component';
import { OrganizationUsersComponent } from './components/organization/organization-users/organization-users.component';
import { OrganizationApplicationsComponent } from './components/organization/organization-applications/organization-applications.component';
import { OrganizationDevicesComponent } from './components/organization/organization-devices/organization-devices.component';
import {MatTableModule} from '@angular/material/table';
import { UserSettingsComponent } from './components/user/user-settings/user-settings.component';
import { UserApplicationsComponent } from './components/user/user-applications/user-applications.component';
import { UserOrganizationSettingsComponent } from './components/user/user-organization-settings/user-organization-settings.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSpinnerOverlayComponent } from './components/mat-spinner-overlay/mat-spinner-overlay.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { WidgetToolbarComponent } from './components/widget-toolbar/widget-toolbar.component';
import { WidgetCreateComponent } from './components/widget-create/widget-create.component';
import { FullscreenControlComponent } from './components/map/fullscreen-control.component';
import { SettingsComponent } from './components/settings/settings.component';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import { UserInvitesComponent } from './components/user/user-invites/user-invites.component';
import { OrganizationInvitesComponent } from './components/organization/organization-invites/organization-invites.component';
import {MatIconModule} from '@angular/material/icon';
import { DashboardCreateDialogComponent } from './components/dashboard-create/dashboard-create-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { PopupConfirmationComponent } from './components/popup-confirmation/popup-confirmation.component';
import { DeleteInfoComponent } from './components/delete-info/delete-info.component';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    url: 'wss://mosquitto.smog.datatask.net:8083',
};

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        MainComponent,
        DashboardNavigationComponent,
        MapComponent,
        TableComponent,
        ChartComponent,
        DashboardCreateComponent,
        PlayerComponent,
        GlobalDateComponent,
        MqttComponent,
        LoginComponent,
        TopNavigationComponent,
        AlertComponent,
        RegisterComponent,
        OrganizationSettingsComponent,
        OrganizationUsersComponent,
        OrganizationApplicationsComponent,
        OrganizationDevicesComponent,
        UserSettingsComponent,
        UserApplicationsComponent,
        UserOrganizationSettingsComponent,
        MatSpinnerOverlayComponent,
        WidgetToolbarComponent,
        WidgetCreateComponent,
        FullscreenControlComponent,
        SettingsComponent,
        UserInvitesComponent,
        OrganizationInvitesComponent,
        DashboardCreateDialogComponent,
        PopupConfirmationComponent,
        DeleteInfoComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        // ChartsModule,
        GridsterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // LeafletModule.forRoot(),
        // BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatTabsModule,
        MatIconModule,
        MatDialogModule,
        LeafletModule,
        NgxDaterangepickerMd.forRoot(),
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        MatTableModule,
        // NgbModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
