import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Organization} from '../organization/model/organization';
import {ActivatedRoute} from '@angular/router';
import {UserOrganization} from '../organization/model/user-organization';
import {OrganizationBackendService} from '../organization/organization-backend.service';
import {UserAccount} from '../../models/user-account';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    public organization: Organization;
    public userAccount: UserAccount;
    public userOrganizations: UserOrganization[];
    public showMenuItem = false;
    public isIndividualOrganization = false;

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private route: ActivatedRoute,
        public authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: { organization: Organization, userAccount: UserAccount,
            userOrganizations: UserOrganization[]  }) => {
            this.organization = data.organization;
            this.userAccount = data.userAccount;
            this.userOrganizations = data.userOrganizations;
            this.refreshData();
        });
    }

    private refreshData(): void {
        this.userOrganizations.forEach(organization => {
            if (this.userAccount.active_organization === organization.organization && !this.organization.individual) {
                if (organization.permissions.find(permission => permission.permission === 'admin')) {
                    this.showMenuItem = true;
                }
            }
        });
        if (this.organization.individual) {
            this.isIndividualOrganization = true;
        }
    }
}


