import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganizationBackendService} from '../../organization/organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {UserAccount} from '../../../models/user-account';
import {ProfileBackendService} from '../../../services-backend/profile-backend.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {PopupConfirmationComponent} from '../../popup-confirmation/popup-confirmation.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
    public enableUserEditButton = false;
    public userAccount: UserAccount;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private profileBackendService: ProfileBackendService,
        public authenticationService: AuthenticationService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.route.parent.data.subscribe((data: { userAccount: UserAccount }) => {
            this.userAccount = data.userAccount;
        });
    }

    public userAccountFirstNameChange(event: Event): void {
        this.userAccount.first_name = (event.target as HTMLInputElement).value;
        this.enableUserEditButton = true;
    }

    public userAccountLastNameChange(event: Event): void {
        this.userAccount.last_name = (event.target as HTMLInputElement).value;
        this.enableUserEditButton = true;
    }

    public saveUser(): void {
        this.profileBackendService.editUserAccount(this.userAccount)
            .subscribe(ret => {
                this.userAccount = ret;
                this.alertService.success('Zaktualizowano imię i nazwisko');
                this.enableUserEditButton = false;
            });
    }

    public deleteUser(): void {
        this.profileBackendService.deleteUserAccount()
            .subscribe(_ => {
                this.alertService.success('Pomyślnie usunięto użytkownika');
                this.router.navigate(['/login']);
            });
    }


    public openDeletePopup(): void {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '250px',
            data: {
                title: 'Usuń konto',
                description: 'Czy jesteś pewien, że chcesz usunąć swoje konto?',
                positiveMessage: 'Usuń',
                negativeMessage: 'Anuluj'
            },
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result === true) {
                this.deleteUser();
            }
        });
    }
}
