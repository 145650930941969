import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IRegisterResponse, IRegisterUser} from '../models/register-user';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private http: HttpClient,
                public authenticationService: AuthenticationService) {
    }

    register(user: IRegisterUser): Observable<IRegisterResponse> {
        return this.http.post<IRegisterResponse>(`${environment.url}/accounts/register/regular/`, user)
            .pipe(map(token => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.authenticationService.setToken(token);
                return token;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${environment.url}/users/${id}`);
    }
}
