/* tslint:disable:variable-name */

export class IUserInvitePermission {
    permission: 'read_all' | 'admin';
}

export class IUserInvite {
    id: number;
    organization: number;
    user: number;
    user_data: { email: string };
    permissions: IUserInvitePermission[];
    creation_date: string;
}

export class IWriteUserInvite {
    name: string;
    organization: number;
    permissions: IUserInvitePermission[];
}

export class IUpdateUserInvite {
    permissions: IUserInvitePermission[];
}


export class UserInvite implements IUserInvite{
    id: number;
    organization: number;
    user: number;
    user_data: { email: string };
    creation_date: string;
    permissions: IUserInvitePermission[];

    selectedPermission: {code: 'read_all' | 'admin', name: string} = null;

    constructor(id: number, organization: number, user: number, user_data: { email: string }, creation_date: string,
                permissions: IUserInvitePermission[]) {
        this.id = id;
        this.organization = organization;
        this.user = user;
        this.user_data = user_data;
        this.creation_date = creation_date;
        this.permissions = permissions;
    }
}

export class UserInviteFactory {
    public static fromBackend(userInvite: IUserInvite): UserInvite {
        return new UserInvite(
            userInvite.id, userInvite.organization, userInvite.user, userInvite.user_data,
            userInvite.creation_date, userInvite.permissions);
    }

    public static toBackend(name: string, organization: number, permission: IUserInvitePermission): IWriteUserInvite {
        return { name, organization, permissions: [permission] };
    }

    public static toBackendUpdate(invite: UserInvite): IUpdateUserInvite {
        return { permissions: invite.permissions };
    }
}
