import {Component, OnInit} from '@angular/core';
import {Organization} from '../model/organization';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganizationBackendService} from '../organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {UserAccount} from '../../../models/user-account';
import {UserOrganization} from '../model/user-organization';
import {MatDialog} from '@angular/material/dialog';
import {PopupConfirmationComponent} from '../../popup-confirmation/popup-confirmation.component';

@Component({
    selector: 'app-organization-settings',
    templateUrl: './organization-settings.component.html',
    styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit {

    public userAccount: UserAccount;
    public organization: Organization;
    public userOrganizations: UserOrganization[] = [];
    public enableOrganizationNameButton = false;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.route.parent.data.subscribe((data: { organization: Organization, userAccount: UserAccount,
            userOrganizations: UserOrganization[] }) => {
            this.organization = data.organization;
            this.userAccount = data.userAccount;
            this.userOrganizations = data.userOrganizations;
            this.checkAccess();
        });
    }

    public organizationNameChange(event: Event): void {
        this.organization.name = (event.target as HTMLInputElement).value;
        this.enableOrganizationNameButton = true;
    }

    public saveOrganizationName(): void {
        this.organizationBackendService.editOrganization(this.organization)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano nazwę');
                this.enableOrganizationNameButton = false;
            });
    }

    public deleteOrganization(): void {
        this.organizationBackendService.deleteOrganization(this.organization.id)
            .subscribe(_ => {
                this.alertService.success('Pomyślnie usunięto organizację');
                this.router.navigate(['user']);
            });
    }

    private checkAccess(): void {
        this.userOrganizations.forEach(organization => {
            if (this.userAccount.active_organization === organization.organization) {
                if (organization.permissions.find(permission => permission.permission === 'admin')) {
                    return true;
                } else {
                    this.router.navigate(['settings/user']);
                }
            }
            if (this.organization.individual) {
                this.router.navigate(['settings/user']);
            }
        });
    }

    public openDeletePopup(): void {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '250px',
            data: {
                title: 'Usuń organizację',
                description: 'Czy jesteś pewien, że chcesz usunąć organizację?',
                positiveMessage: 'Usuń',
                negativeMessage: 'Anuluj'
            },
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result === true) {
                this.deleteOrganization();
            }
        });
    }
}
