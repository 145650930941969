import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import {ProfileResolverService} from '../../resolvers/profile-resolver.service';
import {MqttMeasurementService} from '../../services/mqtt-measurement.service';
import {GlobalDateService} from '../../services/global-date.service';
import {ProfileBackendService} from '../../services-backend/profile-backend.service';
import {Moment} from 'moment';
moment.locale('pl');

@Component({
    selector: 'app-global-date',
    templateUrl: './global-date.component.html',
    styleUrls: ['./global-date.component.scss']
})
export class GlobalDateComponent implements OnInit {

    constructor(
        public profileResolverService: ProfileResolverService,
        public profileBackendService: ProfileBackendService,
        public mqttService: MqttMeasurementService,
        public globalDateService: GlobalDateService,
    ) { }

    public ranges: any = {
        'Ostatnia godzina': [moment().subtract(1, 'hour'), moment()],
        'Ostatnie 3 godziny': [moment().subtract(3, 'hour'), moment()],
        'Ostatnie 10 godzin': [moment().subtract(10, 'hour'), moment()],
        Dzisiaj: [moment().startOf('day'), moment().endOf('day')],
        Wczoraj: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
        'Ostatnie 7 dni': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
        'Ostatnie 30 dni': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
        'Ten miesiac': [moment().startOf('month'), moment().endOf('month')],
        'Poprzedni miesiac': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    public maxDate = moment();

    public initialDate: {
                startDate: Moment,
                endDate: Moment,
            };

    ngOnInit(
    ): void {
        if (this.profileResolverService.profile.measurement_start
            && this.profileResolverService.profile.measurement_start.getTime() !== 0){
            this.initialDate = {
                startDate: moment(this.profileResolverService.profile.measurement_start),
                endDate: moment(this.profileResolverService.profile.getMeasurementEnd()),
            };
        }
        else {
            this.initialDate = {
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
            };
        }
    }

    onOpen(): void {
    }

    changeDate(dates: {startDate: Moment, endDate: Moment}): void {
        if (dates.startDate && dates.endDate) {
            const newDate = {
                measurement_duration: Math.round((dates.endDate.toDate().getTime() - dates.startDate.toDate().getTime()) / 1000),
                measurement_start: dates.startDate.toDate(),
                most_recent_measurements: this.mqttService.currentState(),
            };

            this.globalDateService.emit(newDate);
            this.updateProfile(newDate);
        }
    }

    private updateProfile(date: {
            measurement_duration: number,
            measurement_start: Date,
            most_recent_measurements: boolean,
        }): void {
        const profile = this.profileResolverService.profile;
        profile.most_recent_measurements = date.most_recent_measurements;
        profile.measurement_start = date.measurement_start;
        profile.measurement_duration = date.measurement_duration;
        this.profileBackendService.setProfile(profile).subscribe(p => {
            this.profileResolverService.profile = p;
        });
    }
}
