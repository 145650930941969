import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GridsterConfig, GridsterItemComponent,} from 'angular-gridster2';
import {Widget} from '../../models/widget';
import {Dashboard} from '../../models/dashboard';
import {DashboardBackendService} from '../../services-backend/dashboard-backend.service';
import {WidgetBackendService} from '../../services-backend/widget-backend.service';
import {DeviceBackendService} from '../../services-backend/device-backend.service';
import {Device} from '../../models/device';
import {forkJoin} from 'rxjs';
import {PlayerService} from '../../services/player.service';
import {MainLoaderService} from '../main/main-loader.service';
import {ProfileBackendService} from '../../services-backend/profile-backend.service';
import {ActivatedRoute} from '@angular/router';
import {UserProfile} from '../../models/user-profile';


class ItemChanged {
    cols: number;
    rows: number;
    x: number;
    y: number;
    id: number;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnChanges {

    constructor(
        private dashboardBackendService: DashboardBackendService,
        private widgetBackendService: WidgetBackendService,
        private deviceBackendService: DeviceBackendService,
        private playerService: PlayerService,
        private mainLoaderService: MainLoaderService,
        private profileBackendService: ProfileBackendService,
        private route: ActivatedRoute,
    ) { }

    public devices: Device[] = [];

    public options: GridsterConfig = undefined;

    widgetConfigs: Widget[] = [];
    @Input() dashboard: Dashboard;

    static itemResize(item: ItemChanged, itemComponent: GridsterItemComponent): void {
        console.log('itemResized', item, itemComponent);
    }

    public itemChange(): (item: ItemChanged, itemComponent: GridsterItemComponent) => void {
        // callback is needed to correctly preserve object context
        return (item: ItemChanged, _: GridsterItemComponent) => {
            const widgetId = item.id;
            const widget = this.widgetConfigs.find(el => el.id === widgetId);
            if (widget) {
                widget.position_x = item.x;
                widget.position_y = item.y;
                this.widgetBackendService.setWidget(widget).subscribe(_ => {});
            }
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.dashboard.previousValue || changes.dashboard.currentValue.id !== changes.dashboard.previousValue.id){
            const newId: number = changes.dashboard.currentValue.id;
            if (newId) {
                this.route.data.subscribe((data: { profile: UserProfile }) => {
                    const profile: UserProfile = data.profile;
                    profile.current_dashboard = newId;
                    this.profileBackendService.setProfile(profile).subscribe(_ => {
                    });
                });
            }
            this.refreshWidgets();
            this.playerService.reset();
        }
    }

    private refreshWidgets(): void {
        this.mainLoaderService.emit(true, 'dashboardLoad');
        const deviceObs = this.deviceBackendService.getForDashboard(this.dashboard.id);
        const widgetObs = this.widgetBackendService.getWidgets(this.dashboard.id);

        forkJoin([deviceObs, widgetObs]).subscribe(([devices, widgets]: [Device[], Widget[]]) => {
            this.devices.length = 0;
            this.devices.push(...devices);
            this.widgetConfigs.length = 0;
            this.widgetConfigs.push(...widgets);
            this.mainLoaderService.emit(false, 'dashboardLoad');
        });
    }

    ngOnInit(): void {

        this.options = {
            draggable: {
                enabled: true,
                dragHandleClass: 'drag',
                ignoreContent: true
            },
            resizable: {
                enabled: false
            },
            pushItems: true,
            gridType: 'verticalFixed',
            fixedRowHeight: 150,
            setGridSize: true,
            maxCols: 12,
            minCols: 12,
            itemChangeCallback: this.itemChange(),
            itemResizeCallback: DashboardComponent.itemResize,
        };
    }

    changedOptions(): void {
        this.options.api.optionsChanged();
    }
}
