import { Injectable } from '@angular/core';
import {Measurement} from '../models/measurement';
import {MeasurementTable, TableMeasurement, TableMeasurementRow} from '../models/measurement-table';
import {SensorType} from '../models/device-sensor';

@Injectable({
    providedIn: 'root'
})
export class TableMeasurementConverterService {

    constructor() {
    }

    private static getValueForDeviceSensorId(measurements: Map<number, TableMeasurement>, deviceSensorId: number): number {
        if (measurements.has(deviceSensorId)) {
            return measurements.get(deviceSensorId).value;
        }
        return null;
    }

    convertMeasurementsToTableData(measurements: Measurement[]): MeasurementTable {

        const table = new MeasurementTable(new Map<number, SensorType>(), [], );
        const rows = new Map<number, TableMeasurementRow>();

        for (const measurement of measurements) {
            const date = new Date(measurement.measurementTime);

            if (!rows.has(date.getTime())) {
                rows.set(date.getTime(), new TableMeasurementRow(date, measurement.latitude, measurement.longitude,
                    new Map<number, TableMeasurement>()));
            }
            const row: TableMeasurementRow = rows.get(date.getTime());
            const deviceSensorId: number = measurement.deviceSensorId;

            row.measurements.set(deviceSensorId, new TableMeasurement(measurement.value));
            table.deviceSensorIds.set(deviceSensorId, measurement.sensorType);
        }

        for (const row of rows.values()) {
            table.rows.push(row);
        }

        // console.log(`Converted table data. Total distinct deviceSensorIds: ${table.deviceSensorIds.size}`);
        return table;
    }

    convertTableDataToPrimitiveValues(measurementTable: MeasurementTable): Array<Array<Date | string | number>> {

        const deviceSensorIds: number [] = [];
        for (const deviceSensorId of measurementTable.deviceSensorIds.keys()) {
            deviceSensorIds.push(deviceSensorId);
        }

        const datas = new Array<Array<Date | string | number>>();
        datas.push(['Data', 'Szerokość', 'Wysokość'].concat(deviceSensorIds.map(id =>
            measurementTable.deviceSensorIds.get(id).code)));
        for (const row of measurementTable.rows) {

            datas.push(
                [row.date, row.latitude, row.longitude]
                    .concat(deviceSensorIds.map(deviceSensorId =>
                        TableMeasurementConverterService.getValueForDeviceSensorId(row.measurements, deviceSensorId)
                    ))
            );
        }
        return datas;
    }
}
