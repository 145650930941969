import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MainLoaderService {

    private enabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private enabledArray = [];


    constructor() { }

    public emit(enabled: boolean, name: string): void {
        const index = this.enabledArray.findIndex(el => el === name);
        if (enabled){
            if (index === -1){
                this.enabledArray.push(name);
            }
        }
        else {
            if (index !== -1){
                this.enabledArray.splice(index, 1);
            }
        }

        if (this.enabledArray.length === 0) {
            this.enabled.next(false);
        }
        else {
            this.enabled.next(true);
        }
    }

    public currentValue(): boolean {
        return this.enabled.getValue();
    }

    public observable(): Observable<boolean> {
        return this.enabled.asObservable();
    }
}
