import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Dashboard} from '../../models/dashboard';
import {Organization} from '../organization/model/organization';
import {UserAccount} from '../../models/user-account';
import {UserOrganization} from '../organization/model/user-organization';

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
    styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {
    public isMenuOpened = false;
    public showLink = false;
    public userAccount: UserAccount;
    public userOrganizations: UserOrganization[] = [];

    constructor(
        public authenticationService: AuthenticationService,
        public router: Router,
        private route: ActivatedRoute,
    ) { }

    @Input() dashboard: Dashboard;

    ngOnInit(): void {
        this.route.data.subscribe((data: { userAccount: UserAccount, userOrganizations: UserOrganization[] }) => {
            console.log(data);
            this.userAccount = data.userAccount;
            this.userOrganizations = data.userOrganizations;
            this.checkAccess();
        });
    }

    showMenu(): boolean {
        return this.isMenuOpened = !this.isMenuOpened;
    }

    private checkAccess(): void {
        this.userOrganizations.forEach(organization => {
            if (this.userAccount.active_organization === organization.organization) {
                if (organization.permissions.find(permission => permission.permission === 'admin')) {
                    this.showLink = true;
                } else {
                    this.showLink = false;
                }
            }
        });
    }

}
