import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {ProfileResolverService} from '../resolvers/profile-resolver.service';
import {DashboardBackendService} from '../services-backend/dashboard-backend.service';
import {GlobalDateService} from './global-date.service';
import {MeasurementBackendService} from '../measurement-backend.service';
import {WidgetBackendService} from '../services-backend/widget-backend.service';
import {MqttMeasurementService} from './mqtt-measurement.service';
import {WidgetFocusService} from './widget-focus.service';
import {PlayerConfig, PlayerService} from './player.service';
import {Subscription} from 'rxjs';
import {Device} from '../models/device';
import {Widget} from '../models/widget';
import {Measurement} from '../models/measurement';


export interface PlayerListener {

    playerFrameChange(date: { date: Date | null }): void;

    playerConfigChange(date: PlayerConfig): void;
}

export interface IWidgetComponent extends PlayerListener{
    widgetConfig: Widget;

    devices: Device[];

    appendMeasurements(measurements: Measurement[]): void;

    focusDate(date: Date | null): void;

    onMeasurementsDownload(measurements: Measurement[]): void;
}

@Injectable()
export class GenericWidgetService {

    constructor(
        private router: Router,
        private profileResolverService: ProfileResolverService,
        private dashboardBackendService: DashboardBackendService,
        public globalDateService: GlobalDateService,
        private measurementBackendService: MeasurementBackendService,
        public widgetBackendService: WidgetBackendService,
        private mqttMeasurementService: MqttMeasurementService,
        private widgetFocusService: WidgetFocusService,
        private playerService: PlayerService,
    ) { }

    private dateSubscription: Subscription = null;
    private mqttSubscription: Subscription = null;
    private widgetFocusSubscription: Subscription = null;
    private playerFrameSubscription: Subscription = null;
    private playerConfigSubscription: Subscription = null;

    public header = '';

    public loading = false;

    public onInit(widget: Widget, devices: Device[]): void {
        if (widget.name) {
            this.header = widget.name;
        } else {
            if (devices.length > 0) {
                for (const deviceSensor of widget.device_sensors){
                    for (const device of devices) {
                        if (device.sensors.map(el => el.id).indexOf(deviceSensor) !== -1) {
                            this.header = device.name;
                        }
                    }
                }
            }
        }
    }

    public onAfterInit(widget: IWidgetComponent): void {
        this.dateSubscription = this.globalDateService.observable().subscribe(() => {
            this.refreshData(widget);
        });

        this.mqttSubscription = this.mqttMeasurementService.subscribe(
            measurements => widget.appendMeasurements(measurements));
        this.widgetFocusSubscription = this.widgetFocusService.subscribe(date => widget.focusDate(date));

        this.playerFrameSubscription = this.playerService.subscribeOnFrames(
            date => widget.playerFrameChange(date));
        this.playerConfigSubscription = this.playerService.subscribeOnConfigChange(
            date => widget.playerConfigChange(date));

    }

    public onDestroy(): void {
        if (this.mqttSubscription){
            this.mqttSubscription.unsubscribe();
            this.mqttSubscription = null;
        }
        if (this.dateSubscription){
            this.dateSubscription.unsubscribe();
            this.dateSubscription = null;
        }
        if (this.widgetFocusSubscription){
            this.widgetFocusSubscription.unsubscribe();
            this.widgetFocusSubscription = null;
        }
        if (this.playerFrameSubscription){
            this.playerFrameSubscription.unsubscribe();
            this.playerFrameSubscription = null;
        }
        if (this.playerConfigSubscription){
            this.playerConfigSubscription.unsubscribe();
            this.playerConfigSubscription = null;
        }
    }

    private refreshData(widget: IWidgetComponent): void {
        this.loading = true;
        const dateConfig = this.globalDateService.currentDateConfig();
        const endDate = new Date(dateConfig.measurement_start.getTime() + dateConfig.measurement_duration * 1000);
        const deviceSensorIdToSensorTypeId = new Map<number, number>();
        for (const device of widget.devices ){
            for (const deviceSensor of device.sensors){
                deviceSensorIdToSensorTypeId.set(deviceSensor.id, deviceSensor.sensor_type.id);
            }
        }

        const observable = this.measurementBackendService.getValues(
            widget.widgetConfig.device_sensors, dateConfig.measurement_start, endDate, deviceSensorIdToSensorTypeId);

        observable.subscribe( (measurements) => {
            widget.onMeasurementsDownload(measurements);
            this.loading = false;
        });
    }

    deleteWidget(id): void {
        this.widgetBackendService.deleteWidget(id).subscribe(() => {
            this.router.navigate(['dashboards']);
        });
    }

    public downloadCSV(deviceSensors: number[]): void {
        const dateConfig = this.globalDateService.currentDateConfig();
        const start = dateConfig.measurement_start;
        const end = new Date(dateConfig.measurement_start.getTime() + dateConfig.measurement_duration * 1000);
        this.widgetBackendService.downloadFile(deviceSensors, start, end);
    }
}
