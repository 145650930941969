export interface ISensorType {
    id: number;
    code: string;
    name: string;
    units: string;
    description: string;
    public: boolean;
}

export class SensorType implements ISensorType{
    code: string;
    description: string;
    id: number;
    name: string;
    public: boolean;
    units: string;


    constructor(sensorType: ISensorType) {
        this.code = sensorType.code;
        this.description = sensorType.description;
        this.id = sensorType.id;
        this.name = sensorType.name;
        this.public = sensorType.public;
        this.units = sensorType.units;
    }
}


export class DeviceSensor {
    public id: number;
    public sensorType: SensorType;


    constructor(id: number, sensorType: SensorType) {
        this.id = id;
        this.sensorType = sensorType;
    }
}
