import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {UserProfile} from '../../models/user-profile';
import {ProfileBackendService} from '../../services-backend/profile-backend.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../services/alert.service';


@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('google', {static: true }) google: ElementRef;

    loginForm: FormGroup;
    loading = false;
    returnUrl: string;
    auth2;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private elementRef: ElementRef,
        private globalSettings: ProfileBackendService,
        private ngZone: NgZone,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
        // this.ngOnInit()
    }

    ngOnInit(): void {
        console.log('init login');
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            // login: ['', Validators.required],
            password: ['', Validators.required]
        });
        console.log(this.f);
        this.googleSDK();
        this.facebookSDK();

        console.log('init login');

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    }

    // convenience getter for easy access to form fields
    get f(): any { return this.loginForm.controls; }

    onSubmit(): void {
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                () => {
                    this.redirect();
                },
                error => {
                    console.log(error);
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    googleSDK(): void {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                this.auth2 = window['gapi'].auth2.init({
                    client_id: '603731634566-u5n3hdsnmrd6vu64hloc5gpsnemoi57e.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email'
                });
                this.googleLogin();
            });
        };

        (function(d, s, id){
            let js;
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    }

    googleLogin() {
        this.auth2.attachClickHandler(this.google.nativeElement, {},
            (googleUser) => {
                this.authenticationService.loginGoogle(googleUser.getAuthResponse().id_token).pipe(first()).subscribe(data => {
                    this.redirect();
                });
            }, (error) => {
                alert(JSON.stringify(error, undefined, 2));
            });
    }

    facebookSDK(): void {
        (window as any).fbAsyncInit = function() {
            window['FB'].init({
                appId      : '690096215159234',
                cookie     : true,
                xfbml      : true,
                version    : 'v7.0'
            });
            window['FB'].AppEvents.logPageView();
        };

        (function(d, s, id) {
            let js;
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    facebookLogin(): void {
        window['FB'].getLoginStatus((response) => {
            if (response.status === 'connected') {
                this.authenticationService.loginFacebook(response.authResponse.accessToken).pipe(first()).subscribe( () => {
                    this.redirect();
                    // this.router.navigateByUrl('/');
                });
            } else {
                window['FB'].login((response) => {
                    if (response.authResponse) {
                        this.authenticationService.loginFacebook(response.authResponse.accessToken).pipe(first()).subscribe( () => {
                            this.redirect();
                            // this.router.navigateByUrl('/');
                        });
                    } else {
                        console.log('User login failed');
                    }
                }, {scope: 'email'});
            }
        });
    }

    redirect(): void {
        // let dashboard = localStorage.getItem('active-dashboard');
        // if (dashboard) {
        //     this.router.navigate(['/dashboard', dashboard]);
        // } else {
            // this.router.navigate([this.returnUrl]).then(() => {
            //     location.reload();
            // });
        // }
        // this.globalSettings.getSettings();
        this.ngZone.run(() => {

            this.router.navigate(['/dashboard']).then(_ => {
            });
        });
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.remove();
    }
}
