<div class="login">
    <div class="login-form">
        <h1>Logowanie</h1>
        <!--<alert></alert>-->

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="example-full-width email" appearance="legacy">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="f.email.hasError('email') && !f.email.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="f.email.hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width password" appearance="legacy">
                <mat-label>Hasło</mat-label>
                <input type="password" matInput formControlName="password">
                <mat-error *ngIf="f.password.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <a routerLink="/forgotten-password" class="forgotten-password">Zapomniałeś hasła?</a>

            <div class="form-group">
                <button [disabled]="loading" class="btn btn-custom btn-regular-login">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Zaloguj
                </button>
            </div>
        </form>
        <p class="or">lub</p>

        <button class="btn btn-custom btn-facebook" (click)="facebookLogin()">
            facebook
        </button>
        <button class="btn btn-custom btn-google" #google>
            Google
        </button>

        <p class="register">Nie masz konta? <a routerLink="/register">Zarejestruj się</a></p>
    </div>
</div>
