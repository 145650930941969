/* tslint:disable:variable-name */

export class IUserApplication {
    id: number;
    organization: number;
    user: number;
    user_data: { email: string };
}

export class IWriteUserApplication {
    name: string;
}


export class UserApplication implements IUserApplication{
    id: number;
    organization: number;
    user: number;
    user_data: { email: string };

    constructor(id: number, organization: number,
                user: number, user_data: { email: string }) {
        this.id = id;
        this.organization = organization;
        this.user = user;
        this.user_data = user_data;
    }
}

export class UserApplicationFactory {
    public static fromBackend(userApplication: IUserApplication): UserApplication {
        return new UserApplication(userApplication.id, userApplication.organization,
            userApplication.user, userApplication.user_data);
    }

    public static toBackend(name: string): IWriteUserApplication {
        return { name };
    }
}
