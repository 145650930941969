<input type="text"
               ngxDaterangepickerMd
               (datesUpdated)="changeDate($event)"
               (rangeClicked)="mqttService.enable()"
               [maxDate]="maxDate"
               [timePicker]="true"
               [timePickerSeconds]="false"
               [timePickerIncrement]="1"
               [timePicker24Hour]="true"
               [ranges]="ranges"
               [alwaysShowCalendars]="true"
               [locale]="{format: 'DD/MM/YYYY HH:mm', firstDay: 1}"
               [(ngModel)]="initialDate"
               (click)="onOpen()"
	   			opens="left"
               class="form-control"/>
