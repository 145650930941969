import {Component, OnInit, AfterViewInit, OnDestroy, Input} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-easybutton';
import {Widget} from '../../models/widget';
import {Device} from '../../models/device';
import {MeasurementMarker} from '../../models/measurement-marker';
import {MapMarkerConverterService} from '../../services/map-marker-converter.service';
import {MarkerService} from '../../services/marker.service';
import {Measurement} from '../../models/measurement';
import {FeatureGroup, Marker} from 'leaflet';
import {WidgetFocusService} from '../../services/widget-focus.service';
import {PlayerConfig, PlayerService} from '../../services/player.service';
import {GenericWidgetService, IWidgetComponent} from '../../services/generic-widget.service';
import {HeatmapService} from './heatmap.service';
import {Subscription} from 'rxjs';



@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [GenericWidgetService, HeatmapService],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy, IWidgetComponent {

    constructor(
        private mapMarkerConverterService: MapMarkerConverterService,
        private widgetFocusService: WidgetFocusService,
        private playerService: PlayerService,
        public genericWidgetService: GenericWidgetService,
        public heatmapService: HeatmapService,
    ) {
    }
    public map: L.Map;
    public button: L.Control;

    private markersLayer: FeatureGroup = null;
    public measurements: MeasurementMarker[] = [];
    @Input() public devices: Device[];
    @Input() public widgetConfig: Widget;

    options = {
        layers: [
            L.tileLayer('https://maps.mysticmobileapps.org/planet/{z}/{x}/{y}.png', {
                maxZoom: 20,
            })
        ],
        zoom: 13,
        scrollWheelZoom: false,
    };

    private sensorIdToType: Map<number, string> = new Map<number, string>();

    private subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.genericWidgetService.onInit(this.widgetConfig, this.devices);
        this.heatmapService.getHeatmapConfigs();
    }

    public ngAfterViewInit(): void {
        this.genericWidgetService.onAfterInit(this);
    }

    public ngOnDestroy(): void {
        this.genericWidgetService.onDestroy();
        while (this.subscriptions.length > 0){
            const subscription = this.subscriptions.pop();
            subscription.unsubscribe();
        }
    }

    onMeasurementsDownload(measurements: Measurement[]): void {
        this.playerService.appendMeasurements(measurements);
        this.measurements = this.mapMarkerConverterService.measurementsToMarkers(
            measurements, this.widgetConfig.main_sensor);
        this.prepareData(this.measurements);
    }


    getDeviceType(sensorId: number): string {
        if (this.sensorIdToType.has(sensorId)){
            return this.sensorIdToType.get(sensorId);
        }
        else {
            let newType: string;
            if ([...this.sensorIdToType.values()].indexOf('square') === -1){
                newType = 'square';
            }
            else if ([...this.sensorIdToType.values()].indexOf('triangle') === -1) {
                newType = 'triangle';
            }
            else {
                newType = 'circle';
            }
            this.sensorIdToType.set(sensorId, newType);
            return this.sensorIdToType.get(sensorId);
        }
    }

    onMapReady(map: L.Map): void {
        this.map = map;
        setTimeout(() => {
            map.invalidateSize(true);
            this.centerMapOnMeasurements();
        }, 1);
        this.subscriptions.push(
            this.genericWidgetService.globalDateService.observable().subscribe(
                () => this.heatmapService.refreshHeatmap(this.map)));
    }

    focusDate(date: Date): void {

        let chosenDate: Date;
        let chosenMarker: Marker;
        for (const marker of this.markersLayer.getLayers()) {

            const markerDate = new Date(parseInt((marker as Marker).options.alt, 10));
            if (markerDate >= date) {
                if (chosenDate === undefined || markerDate < chosenDate){
                    chosenDate = markerDate;
                    chosenMarker = marker as Marker;
                }
            }
        }
        if (chosenMarker){
            chosenMarker.openPopup();
        }
    }

    createMapMarker(measurementMarker: MeasurementMarker): Marker {
        const unixDate: number = measurementMarker.decidingDate.getTime();

        const condition: string = measurementMarker.getConditionsString();

        const type = this.getDeviceType(measurementMarker.decidingSensorId);
        const marker = L.marker([measurementMarker.latitude, measurementMarker.longitude], {
            alt: unixDate.toString(),
            icon: MarkerService.getIcon(condition, type, unixDate.toString())
        });
        marker.bindPopup(measurementMarker.getPopupString());
        return marker;
    }

    private centerMapOnMeasurements(): void {
        if (this.markersLayer && this.markersLayer.getLayers().length > 0) {
            const bounds = this.markersLayer.getBounds();
            this.map.fitBounds(bounds);
            if (this.button){
                this.button.remove();
            }
            this.button = L.easyButton('<span class="target">&target;</span>', (btn, map2) => {
                map2.fitBounds(bounds, { padding: [10, 10] });
            }, '', 'home-map-button');
            this.button.addTo(this.map);
        }
    }

    prepareData(measurementMarkers: MeasurementMarker []): void {
        const map = this.map;

        const leafletMarkers: Marker[] = [];
        measurementMarkers.forEach(measurementMarker => {
            leafletMarkers.push(this.createMapMarker(measurementMarker));
        });


        if (this.map && this.markersLayer) {
            this.map.removeLayer(this.markersLayer);
            this.markersLayer = null;
            if (this.button) {
                this.map.removeControl(this.button);
            }
        }
        this.markersLayer = L.featureGroup(leafletMarkers);
        map.addLayer(this.markersLayer);

        this.markersLayer.on('click', (e: any) => {
            this.widgetFocusService.emitValue(new Date(parseInt(e.originalEvent.target.alt, 10)));
        });

        this.centerMapOnMeasurements();
    }

    public appendMeasurements(measurements: Measurement[]): void {
        measurements = measurements.filter(
            measurement => this.widgetConfig.device_sensors.includes(measurement.deviceSensorId));
        if (this.measurements.length === 0){
            this.measurements = this.mapMarkerConverterService.measurementsToMarkers(measurements,
                this.widgetConfig.main_sensor);
            this.prepareData(this.measurements);
        }
        else if (this.map && this.markersLayer) {

            const measurementMarkers = this.mapMarkerConverterService.measurementsToMarkers(measurements,
                this.widgetConfig.main_sensor);

            this.measurements.push(...measurementMarkers);

            for (const measurementMarker of measurementMarkers) {
                this.markersLayer.addLayer(this.createMapMarker(measurementMarker));
            }
        }
    }

    public playerFrameChange(date: { date: Date | null }): void {
        if (date.date === null){
            this.showAllMarkers();
            return;
        }
        if (this.markersLayer) {
            const snakeStart: Date = this.playerService.getSnakeStart();
            for (const m of this.markersLayer.getLayers()) {
                const marker = m as Marker;
                const time = parseInt(marker.getElement().getAttribute('alt'), 10);
                if (time >= snakeStart.getTime() && time <= date.date.getTime()) {
                    if (marker.getElement().classList.contains('hide-marker')) {
                        marker.getElement().classList.remove('hide-marker');
                    }
                } else {
                    if (!marker.getElement().classList.contains('hide-marker')) {
                        marker.getElement().classList.add('hide-marker');
                    }
                }
            }
        }
        this.heatmapService.playerFrameChange(date);
    }

    private showAllMarkers(): void{
        if (this.markersLayer) {
            for (const m of this.markersLayer.getLayers()) {
                const marker = m as Marker;
                if (marker.getElement().classList.contains('hide-marker')) {
                    marker.getElement().classList.remove('hide-marker');
                }
            }
        }
    }

    public playerConfigChange(config: PlayerConfig): void {
        if (config.playing) {
            this.playerFrameChange({date: config.currentDate});
        } else {
            this.showAllMarkers();
            this.heatmapService.playerConfigChange(config);
        }
    }
}
