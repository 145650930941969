export interface IDashboard {
    id: number;
    name: string;
    owner: number | null;
    widgets: number[];
}

export interface IWriteDashboard {
    name: string;
}

export class Dashboard implements IDashboard {
    id: number;
    name: string;
    owner: number | null;
    widgets: number[];


    constructor(dashboard: IDashboard) {
        this.id = dashboard.id;
        this.name = dashboard.name;
        this.owner = dashboard.owner;
        this.widgets = dashboard.widgets;
    }
}
