import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Organization} from '../../organization/model/organization';
import {MatTable} from '@angular/material/table';
import {UserAccount} from '../../../models/user-account';
import {OrganizationBackendService} from '../../organization/organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {forkJoin} from 'rxjs';
import {UserInvite} from '../../organization/model/user-invite';
import {Router} from '@angular/router';

@Component({
    selector: 'app-user-invites',
    templateUrl: './user-invites.component.html',
    styleUrls: ['./user-invites.component.scss']
})
export class UserInvitesComponent implements OnInit {

    public organizations: Organization[] = [];
    private organizationIdToInvite = new Map<number, UserInvite>();
    @ViewChild(MatTable) matPositions: MatTable<any>;
    @Input() private userAccount: UserAccount = null;
    columnsToDisplay: string[] = ['name', 'actions'];

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.refreshData();

    }

    private refreshData(): void {
        forkJoin([
            this.organizationBackendService.getAllOrganizations(),
            this.organizationBackendService.getInvitesForUser(this.userAccount.id)
        ])
            .subscribe(([organizations, userInvites]: [Organization[], UserInvite[]]) => {

                this.organizationIdToInvite.clear();
                this.organizations.length = 0;

                for (const userInvite of userInvites){
                    const organizationIndex = organizations.findIndex(
                        organization => organization.id === userInvite.organization);
                    if (organizationIndex !== -1) {
                        const organization = organizations[organizationIndex];
                        this.organizations.push(organization);
                        this.organizationIdToInvite.set(organization.id, userInvite);
                    }
                }

                this.matPositions.renderRows();
            });
    }

    public deleteInvite(organization: Organization): void {
        this.organizationBackendService.deleteInvite(this.organizationIdToInvite.get(organization.id))
            .subscribe(_ => {
                this.alertService.success('Usunieto zaproszenie');
                const index = this.organizations.findIndex(org => org.id === organization.id);
                if (index !== -1) {
                    this.organizations.splice(index, 1);
                    this.matPositions.renderRows();
                }
            });
    }

    public acceptInvite(organization: Organization): void {
        this.organizationBackendService.acceptInvite(this.organizationIdToInvite.get(organization.id))
            .subscribe(_ => {
                this.alertService.success('Zaakceptowano zaproszenie');
                const currentRoute = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                    this.router.navigate([currentRoute]));
            });
    }
}
