import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ISensorType, SensorType} from '../models/device-sensor';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Device, IDevice} from '../models/device';
import {ErrorHandlerService} from '../services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceBackendService {

    constructor(
        private httpClient: HttpClient,
        private errorHandlerService: ErrorHandlerService,
    ) { }


    public getForDashboard(dashboardId: number): Observable<Device[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<IDevice[]>
        (`${environment.url}/devices/?mobile=true&include_sensors=true&dashboard_ids=${dashboardId}`))
            .pipe(map((res: IDevice[]) => res.map(device => new Device(device))));
    }

    public getByModelId(modelId: string): Observable<Device> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<IDevice>
        (`${environment.url}/devices/${modelId}/getByModelId/?include_sensors=true`))
            .pipe(map((res: IDevice) => new Device(res)));
    }

    public getByOrganizationId(organizationId: number): Observable<Device[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<IDevice[]>
        (`${environment.url}/devices/?organization=${organizationId}&include_sensors=true`))
            .pipe(map((res: IDevice[]) => res.map(device => new Device(device))));
    }

    public getPrivateByOrganizationId(organizationId: number): Observable<Device[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<IDevice[]>
        (`${environment.url}/devices/?public=false&organization=${organizationId}&include_sensors=true`))
            .pipe(map((res: IDevice[]) => res.map(device => new Device(device))));
    }

    public getOnlyPublic(): Observable<Device[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<IDevice[]>
        (`${environment.url}/devices/?public=true&mobile=true&include_sensors=true`))
            .pipe(map((res: IDevice[]) => res.map(device => new Device(device))));
    }

    public deleteDevice(id: number): Observable<void> {
        return this.errorHandlerService.pipeThroughErrorHandler(
            this.httpClient.delete<void>(`${environment.url}/devices/${id}/`));
    }

    public updateDevice(device: Device): Observable<Device> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.put<IDevice>(
            `${environment.url}/devices/${device.id}/`,
            device
        )).pipe(map(res => {
            return new Device(res);
        }));
    }
}
