import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Organization} from '../../organization/model/organization';
import {MatTable} from '@angular/material/table';
import {UserAccount} from '../../../models/user-account';
import {OrganizationBackendService} from '../../organization/organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {forkJoin} from 'rxjs';
import {UserApplication} from '../../organization/model/user-application';

@Component({
    selector: 'app-user-applications',
    templateUrl: './user-applications.component.html',
    styleUrls: ['./user-applications.component.scss']
})
export class UserApplicationsComponent implements OnInit {

    public organizationCreateButtonEnabled = false;
    public newOrganizationName = '';

    public organizations: Organization[] = [];
    private organizationIdToApplication = new Map<number, UserApplication>();
    @ViewChild(MatTable) matPositions: MatTable<any>;
    @Input() private userAccount: UserAccount = null;
    columnsToDisplay: string[] = ['name', 'actions'];

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
    ) { }

    ngOnInit(): void {
        this.refreshData();

    }

    private refreshData(): void {
        forkJoin([
            this.organizationBackendService.getAllOrganizations(),
            this.organizationBackendService.getAllUserApplicationsForUser(this.userAccount.id)
        ])
            .subscribe(([organizations, userApplications]: [Organization[], UserApplication[]]) => {

                this.organizationIdToApplication.clear();
                this.organizations.length = 0;

                for (const userApplication of userApplications){
                    const organizationIndex = organizations.findIndex(
                        organization => organization.id === userApplication.organization);
                    if (organizationIndex !== -1) {
                        const organization = organizations[organizationIndex];
                        this.organizations.push(organization);
                        this.organizationIdToApplication.set(organization.id, userApplication);
                    }
                }

                this.matPositions.renderRows();
            });
    }

    public organizationNameChange(event: Event): void {
        this.newOrganizationName = (event.target as HTMLInputElement).value;
        this.organizationCreateButtonEnabled = this.newOrganizationName !== '';
    }

    public deleteApplication(organization: Organization): void {
        this.organizationBackendService.denyApplication(this.organizationIdToApplication.get(organization.id))
            .subscribe(_ => {
                this.alertService.success('Usunieto prośbę o dołączenie');
                const index = this.organizations.findIndex(org => org.id === organization.id);
                if (index !== -1) {
                    this.organizations.splice(index, 1);
                    this.matPositions.renderRows();
                }
            });
    }

    public sendApplication(): void {
        this.organizationBackendService.sendApplication(this.newOrganizationName)
            .subscribe(_ => {
                this.alertService.success('Stworzono prośbę o dołączenie');
                this.organizationCreateButtonEnabled = false;
                this.newOrganizationName = '';
                this.refreshData();
            });
    }

}
