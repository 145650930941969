
export interface IOrganization {
    id: number;
    name: string;
    individual: boolean;
}

export interface IWriteOrganization {
    name: string;
}

export class Organization implements IOrganization {
    id: number;
    individual: boolean;
    name: string;

    constructor(id: number, individual: boolean, name: string) {
        this.id = id;
        this.individual = individual;
        this.name = name;
    }
}

export class OrganizationFactory {
    public static fromBackend(organization: IOrganization): Organization {
        return new Organization(organization.id, organization.individual, organization.name);
    }
    public static toBackend(organization: Organization): IWriteOrganization {
        return {name: organization.name};
    }
}
