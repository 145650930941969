import {SensorType} from './device-sensor';

export class ChartMeasurement {


    constructor(date: Date, value: number) {

        this.date = new Date(date.getTime());
        this.value = value;
    }

    date: Date;
    value: number;
}

export class ChartLineSerie {

    constructor(sensorType: SensorType, deviceSensorId: number, measurements: ChartMeasurement[], color: string) {
        this.sensorType = sensorType;
        this.deviceSensorId = deviceSensorId;
        this.measurements = measurements;
        this.color = color;
    }

    deviceName?: string;
    sensorType: SensorType;
    deviceSensorId: number;
    measurements: ChartMeasurement [];
    color: string;
    maxGap?: number;
}

export class ChartMeasurements {

    public minDate: Date;
    public maxDate: Date;

    constructor(lineSeries: ChartLineSerie[]) {
        this.lineSeries = lineSeries;
    }

    lineSeries: ChartLineSerie [];

    getDaySpan(): number {
        return  (this.maxDate.getTime() -
            this.minDate.getTime()) / 1000 / 60 / 60 / 24;
    }
}
