<mat-table [dataSource]="organizations" *ngIf="organizations" class="mat-effects">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Organizacja</mat-header-cell>
        <mat-cell *matCellDef="let organization"> {{ organization.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef >Akcje</mat-header-cell>
        <mat-cell *matCellDef="let organization">
            <button (click)="deleteApplication(organization)">&times; Usuń prośbę</button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: false"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
</mat-table>

<div class="organization-application">
    <mat-form-field>
        <mat-label>Organizacja</mat-label>
        <input matInput placeholder="Nazwa organizacji..." [value]="newOrganizationName"
               (input)="organizationNameChange($event)">
    </mat-form-field>
    <button (click)="sendApplication()" [disabled]="!organizationCreateButtonEnabled">Wyślij prośbę o dołączenie</button>
</div>