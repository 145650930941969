import {Component, Input, OnInit} from '@angular/core';
import {GenericWidgetService} from '../../services/generic-widget.service';
import {Widget} from '../../models/widget';

@Component({
    selector: 'app-widget-toolbar',
    templateUrl: './widget-toolbar.component.html',
    styleUrls: ['./widget-toolbar.component.scss']
})
export class WidgetToolbarComponent implements OnInit {

    @Input() public widgetConfig: Widget;

    constructor(
        public genericWidgetService: GenericWidgetService,
    ) { }

    ngOnInit(): void {
    }

}
