import {Component, NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {AuthGuardService} from './services/auth-guard.service';
import {ProfileResolverService} from './resolvers/profile-resolver.service';
import {LoginComponent} from './components/login/login.component';
import {SensorTypeResolverService} from './resolvers/sensor-type-resolver.service';
import {RegisterComponent} from './components/register/register.component';
import {OrganizationSettingsComponent} from './components/organization/organization-settings/organization-settings.component';
import {UserAccountResolverService} from './resolvers/user-account-resolver.service';
import {OrganizationResolverService} from './components/organization/organization-resolver.service';
import {UserSettingsComponent} from './components/user/user-settings/user-settings.component';
import {DashboardCreateComponent} from './components/dashboard-create/dashboard-create.component';
import {WidgetCreateComponent} from './components/widget-create/widget-create.component';
import {SettingsComponent} from './components/settings/settings.component';
import {OrganizationDevicesComponent} from './components/organization/organization-devices/organization-devices.component';
import {OrganizationUsersComponent} from './components/organization/organization-users/organization-users.component';
import {UserOrganizationResolverService} from './components/user/user-organization-resolver.service';
import {DashboardCreateDialogComponent} from './components/dashboard-create/dashboard-create-dialog.component';
import {DeleteInfoComponent} from './components/delete-info/delete-info.component';

const routes: Routes = [
    {
        path: 'dashboard',
        component: MainComponent,
        data: { requiresLogin: true },
        canActivate: [AuthGuardService],
        resolve: {
            profile: ProfileResolverService,
            sensorType: SensorTypeResolverService,
            userAccount: UserAccountResolverService,
            userOrganizations: UserOrganizationResolverService,
        },
        // children: [
        //     {
        //         path: 'create',
        //         pathMatch: 'full',
        //         component: DashboardCreateDialogComponent,
        //         data: { requiresLogin: true },
        //     },
        // ]
    },

    {path: 'dashboard/create',
        component: DashboardCreateDialogComponent, pathMatch: 'full', data: { requiresLogin: true },
        canActivate: [AuthGuardService], },

    {path: 'deleteInstructions',
        component: DeleteInfoComponent, pathMatch: 'full', data: { requiresLogin: false }, },

    {path: 'dashboard/:id/createWidget',
        component: WidgetCreateComponent, pathMatch: 'full', data: { requiresLogin: true },
        canActivate: [AuthGuardService],
        resolve: {
            sensorType: SensorTypeResolverService,
            userAccount: UserAccountResolverService,
        },
    },
    {path: 'dashboard/:id', component: MainComponent, pathMatch: 'full', data: { requiresLogin: true },
        canActivate: [AuthGuardService], resolve: {
            profile: ProfileResolverService,
            sensorType: SensorTypeResolverService,
            userAccount: UserAccountResolverService,
            userOrganizations: UserOrganizationResolverService,
        }},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},

    {
        path: 'settings',
        component: SettingsComponent,
        resolve: {
            userAccount: UserAccountResolverService,
            organization: OrganizationResolverService,
            profile: ProfileResolverService,
            userOrganizations: UserOrganizationResolverService,
        },
        children: [
            {
                path: 'organization',
                component: OrganizationSettingsComponent,
                canActivate: [AuthGuardService],
                data: { requiresLogin: true },
            },
            {
                path: 'user',
                component: UserSettingsComponent,
                canActivate: [AuthGuardService],
                data: { requiresLogin: true },
            },
            {
                path: 'devices',
                component: OrganizationDevicesComponent,
                canActivate: [AuthGuardService],
                data: { requiresLogin: true },
            },
            {
                path: 'members',
                component: OrganizationUsersComponent,
                canActivate: [AuthGuardService],
                data: { requiresLogin: true },
            },
        ]
    },

    // {
    //     path: 'organization',
    //     pathMatch: 'full',
    //     data: { requiresLogin: true },
    //     component: OrganizationSettingsComponent,
    //     canActivate: [AuthGuardService],
    //     resolve: {
    //         userAccount: UserAccountResolverService,
    //         organization: OrganizationResolverService,
    //         profile: ProfileResolverService,
    //     }
    // },
    // {
    //     path: 'user',
    //     pathMatch: 'full',
    //     data: { requiresLogin: true },
    //     component: UserSettingsComponent,
    //     canActivate: [AuthGuardService],
    //     resolve: {
    //         userAccount: UserAccountResolverService,
    //         profile: ProfileResolverService,
    //     }
    // },
    {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    {path: '**', redirectTo: '/dashboard', pathMatch: 'full'},
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })

export const AppRoutingModule = RouterModule.forRoot(routes);
