import { Injectable } from '@angular/core';
import {ProfileResolverService} from '../resolvers/profile-resolver.service';
import {ProfileBackendService} from '../services-backend/profile-backend.service';
import {ReplaySubject, Subscription} from 'rxjs';
import {Measurement} from '../models/measurement';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {DeviceBackendService} from '../services-backend/device-backend.service';
import {Device, ISensor} from '../models/device';

interface UnprocessedMeasurement {
    signalStrength: number;
    timestamp: Date;
    pm1: number;
    pm25: number;
    pm4: number;
    pm10: number;
    lat: number;
    lon: number;
    alt: number;
    speed: number;
    status: number;
    deviceName: string;
}

@Injectable({
    providedIn: 'root'
})
export class MqttMeasurementService {

    private enabled = this.profileResolverService.profile.most_recent_measurements;
    private mqttSubject: ReplaySubject<Measurement[]> = new ReplaySubject<Measurement[]>();

    private websocketSubscription: Subscription = null;
    private deviceNameToDevice: Map<string, Device> = new Map<string, Device>();

    constructor(
        public profileResolverService: ProfileResolverService,
        public profileBackendService: ProfileBackendService,
        private mqttService: MqttService,
        private deviceBackendService: DeviceBackendService,
    ) {
        if (this.enabled){
            this.subscribeOnWebsocket();
        }
    }

    public subscribe(callback: (measurements: Measurement[]) => void): Subscription {
        return this.mqttSubject.asObservable().subscribe(measurements => {
           return callback(measurements);
        });
    }

    public enable(): void {
        this.setEnabled(true);
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
        this.updateProfile();
        if (this.enabled){
            this.subscribeOnWebsocket();
        }
        else {
            if (this.websocketSubscription) {
                this.websocketSubscription.unsubscribe();
                this.websocketSubscription = null;
            }
        }
    }

    private subscribeOnWebsocket(): void {
        if (this.websocketSubscription) {
            this.websocketSubscription.unsubscribe();
            this.websocketSubscription = null;
        }
        this.websocketSubscription = this.mqttService.observe('#').subscribe((message: IMqttMessage) => {
            if (message.topic.indexOf('D/') > -1 && (message.topic.indexOf('DATA_F') > -1)) {
                const deviceName = message.topic.slice(9, message.topic.length);
                const protocol = this.getUintFromMessage(message.payload.slice(0, 1));
                if (protocol === 1) {
                    const signalStrength = this.getUintFromMessage(message.payload.slice(1, 2));
                    const timestamp = new Date(this.getUintFromMessage(message.payload.slice(2, 8)));
                    const pm1 = this.getUintFromMessage(message.payload.slice(8, 10)) / 10;
                    const pm25 = this.getUintFromMessage(message.payload.slice(10, 12)) / 10;
                    const pm4 = this.getUintFromMessage(message.payload.slice(12, 14)) / 10;
                    const pm10 = this.getUintFromMessage(message.payload.slice(14, 16)) / 10;
                    const lat = this.getIntFromMessage(message.payload.slice(16, 20)) / (10 ** 7);
                    const lon = this.getIntFromMessage(message.payload.slice(20, 24)) / (10 ** 7);
                    const alt = this.getIntFromMessage(message.payload.slice(24, 26)) / 10;
                    const speed = this.getIntFromMessage(message.payload.slice(26, 28)) / 10;
                    const status = this.getIntFromMessage(message.payload.slice(28, 32));
                    const unprocessedMeasurement: UnprocessedMeasurement = {
                        signalStrength, timestamp, pm1, pm25, pm4, pm10, lat, lon, alt, speed, status, deviceName
                    };
                    if (this.deviceNameToDevice.has(deviceName)){
                        this.handleUnprocessedMeasurement(unprocessedMeasurement);
                    }
                    else{
                        this.deviceBackendService.getByModelId(deviceName).subscribe(device => {
                            this.deviceNameToDevice.set(deviceName, device);
                            this.handleUnprocessedMeasurement(unprocessedMeasurement);
                        });
                    }
                }
            }
        });

    }

    private handleUnprocessedMeasurement(unprocessedMeasurement: UnprocessedMeasurement): void{
        const device = this.deviceNameToDevice.get(unprocessedMeasurement.deviceName);
        const measurements: Measurement[] = [];
        for (const code of ['pm1', 'pm10', 'pm4', 'pm25', 'alt', 'speed']){
            const sensor = device.sensors.find( (el: ISensor) => {
                return el.sensor_type.code === code;
            });
            if (sensor){
                const measurement = Measurement.getFromValues(unprocessedMeasurement[code],
                    unprocessedMeasurement.timestamp, unprocessedMeasurement.lat, unprocessedMeasurement.lon,
                    sensor.id, sensor.sensor_type);
                measurements.push(measurement);
            }
        }
        if (measurements.length > 0) {
            this.mqttSubject.next(measurements);
        }
    }

    public currentState(): boolean {
        return this.enabled;
    }

    private updateProfile(): void {
        const profile = this.profileResolverService.profile;
        profile.most_recent_measurements = this.currentState();
        this.profileBackendService.setProfile(profile).subscribe(p => {
            this.profileResolverService.profile = p;
        });
    }

    getIntFromMessage(message): number {
        const buf = new Buffer(message, 'binary');
        if (message.length === 2) {
            return buf.readUInt16LE(0);
        } else if (message.length === 4) {
            return buf.readUInt32LE(0);
        } else {
            return buf.readUIntLE(0, message.byteLength);
        }
    }

    getUintFromMessage(message): number {
        const buf = new Buffer(message, 'binary');
        if (message.length === 2) {
            return buf.readUInt16LE(0);
        } else if (message.length === 4) {
            return buf.readUInt32LE(0);
        } else {
            return buf.readUIntLE(0, message.byteLength);
        }
    }

    coordinateToFloat(coordinate): number {
        const degreesAndMinutes = this.getIntFromMessage(coordinate.slice(0, 2));
        const fraction = this.getIntFromMessage(coordinate.slice(2, 6));
        const degrees = Math.floor(degreesAndMinutes / 100);
        let minutes = degreesAndMinutes % 100;

        minutes += fraction / 1000000.0;

        return parseFloat((degrees + minutes / 60.0).toFixed(7));
    }
}
