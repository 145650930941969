import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserOrganization} from '../model/user-organization';
import {OrganizationBackendService} from '../organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {UserOrganizationUpdateService} from '../../../user-organization-update.service';
import {Organization} from '../model/organization';
import {MatTable} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {MatSelectChange} from '@angular/material/select';
import {UserAccountResolverService} from '../../../resolvers/user-account-resolver.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAccount} from '../../../models/user-account';

@Component({
    selector: 'app-organization-users',
    templateUrl: './organization-users.component.html',
    styleUrls: ['./organization-users.component.scss']
})
export class OrganizationUsersComponent implements OnInit, OnDestroy {
    availablePermissions: {code: 'read_all' | 'admin', name: string}[]
        = [{code: 'admin', name: 'Administrator'}, {code: 'read_all', name: 'Użytkownik'}];

    public userAccount: UserAccount;
    public userOrganizations: UserOrganization[] = [];
    public userOrganizationsForUser: UserOrganization[] = [];
    public organization: Organization;
    // @Input() organization: Organization;
    columnsToDisplay: string[] = ['user', 'permissions', 'actions'];
    @ViewChild(MatTable) matPositions: MatTable<any>;

    private subscriptions: Subscription[] = [];

    private accountId = -1;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
        private userOrganizationUpdateService: UserOrganizationUpdateService,
        private userAccountResolverService: UserAccountResolverService,
    ) { }


    private static deleteFromArray<T>(array: T[], object: T): void{
        const index = array.indexOf(object);
        if (index !== -1){
            array.splice(index, 1);
        }
    }

    ngOnInit(): void {
        this.route.parent.data.subscribe((data: { organization: Organization, userAccount: UserAccount,
            userOrganizations: UserOrganization[] }) => {
            this.organization = data.organization;
            this.userOrganizationsForUser = data.userOrganizations;
            this.userAccount = data.userAccount;
            this.checkAccess();
            this.refreshData();
        });
        this.subscribeOnUserOrganizationChanges();
        this.subscribeOnAccountChanges();
    }

    private refreshData(): void {
        this.organizationBackendService.getAllUserOrganizations(this.organization.id).subscribe(ret => {
            for (const userOrganization of ret){
                userOrganization.selectedPermission = this.getSelectedPermission(userOrganization);
            }
            this.userOrganizations.length = 0;
            this.userOrganizations.push(...ret);
            this.matPositions.renderRows();
        });
    }

    private checkAccess(): void {
        this.userOrganizationsForUser.forEach(organization => {
            if (this.userAccount.active_organization === organization.organization) {
                if (organization.permissions.find(permission => permission.permission === 'admin')) {
                    return true;
                } else {
                    this.router.navigate(['settings/user']);
                }
            }
            if (this.organization.individual) {
                this.router.navigate(['settings/user']);
            }
        });
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length > 0){
            this.subscriptions[0].unsubscribe();
            this.subscriptions.splice(0, 1);
        }
    }

    private subscribeOnUserOrganizationChanges(): void {
        this.subscriptions.push(this.userOrganizationUpdateService
            .observable()
            .subscribe(userOrganization => {
                userOrganization.selectedPermission = this.getSelectedPermission(userOrganization);
                this.userOrganizations.push(userOrganization);
                this.matPositions.renderRows();
            }));
    }

    updateUserPermissions(userOrganization: UserOrganization, event: MatSelectChange): void {
        const value: {code: 'admin' | 'read_all', name: string} = event.value;
        userOrganization.permissions.length = 0;
        if (value.code === 'admin'){
            userOrganization.permissions.push({permission: 'read_all'}, {permission: 'admin'});
        }
        else if (value.code === 'read_all') {
            userOrganization.permissions.push({permission: 'read_all'});
        }
        this.organizationBackendService.editUserOrganization(userOrganization)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano uprawnienia');
            });
    }


    private getSelectedPermission(userOrganization: UserOrganization): {code: 'read_all' | 'admin', name: string} {
        let permission = 'read_all';
        if (userOrganization.permissions.findIndex(el => el.permission === 'admin') !== -1){
           permission = 'admin';
        }
        const index = this.availablePermissions.findIndex(el => el.code === permission);
        if (index !== -1){
            return this.availablePermissions[index];
        }
        else {
            return null;
        }
    }

    removeUserOrganization(userOrganization: UserOrganization): void {
        this.organizationBackendService.deleteUserOrganization(userOrganization)
            .subscribe(_ => {
            this.alertService.success('Użytkownik został usunięty z organizacji');
            OrganizationUsersComponent.deleteFromArray(this.userOrganizations, userOrganization);
            this.matPositions.renderRows();
        });
    }

    public isSelf(userOrganization: UserOrganization): boolean{
        return userOrganization.user === this.accountId;
    }

    private subscribeOnAccountChanges(): void {
        this.subscriptions.push(this.userAccountResolverService.observable().subscribe(account => {
            this.accountId = account.id;
        }));
    }
}
