<div class="wrapper">
    <div>
        <p class="heading">Dodaj dashboard</p>
    </div>
    <div>
        <form #dashboardForm="ngForm" (ngSubmit)="createDashboard(dashboardForm.form);">
            <div class="form-group">
                <label for="dashboard-name">Lorem ipsum dolor sit amet</label>
                <div class="input-group">
                    <input id="dashboard-name" name="dashboard-name" class="form-control" type="text" ngModel
                           required minlength="3">
                </div>
            </div>
            <div class="buttons">
                <button type="button" class="btn-close" aria-label="Close" mat-dialog-close>
                    <span aria-hidden="true">Anuluj</span>
                </button>
                <button [disabled]="loading" type="submit" class="btn btn-add">
                    + Dodaj
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                </button>
            </div>
        </form>

    </div>
</div>
