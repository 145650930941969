import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HeatmapConfig, HeatmapConfigFactory, IHeatmapConfig} from './heatmap';
import {HttpClient} from '@angular/common/http';
import {ImageOverlay} from 'leaflet';
import {GlobalDateService} from '../../services/global-date.service';
import * as L from 'leaflet';
import {PlayerListener} from '../../services/generic-widget.service';
import {PlayerConfig} from '../../services/player.service';
import {CallbackBouncerService} from '../../services/callback-bouncer.service';

@Injectable()
export class HeatmapService implements PlayerListener {

    constructor(
        private http: HttpClient,
        private globalDateService: GlobalDateService,
    ) { }

    private bouncerService: CallbackBouncerService = new CallbackBouncerService(1000);
    private selectedHeatmapConfig: HeatmapConfig = null;
    private imageOverlay: ImageOverlay = null;
    private currentHeatmapConfig: HeatmapConfig = null;
    public  heatmapConfigs: HeatmapConfig[]  = [
        HeatmapConfigFactory.empty(),
    ];

    private playerDateOverride: Date = null;


    // For debugging purposes
    // noinspection JSUnusedLocalSymbols
    private static createBlackBackground(config: HeatmapConfig): ImageOverlay {
        const url = 'https://images.squarespace-cdn.com/content/v1/5417aec0e4b03dbd0c090a08/1591139279402-1KWDRB9YF' +
            'SS0R3YQ6P1K/ke17ZwdGBToddI8pDm48kEpVg-ILAPna1wRh-xAJ9fRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7' +
            'XRK3dMEBRBhUpwEv36x-EUL2-BSQ5feDhwGCbXuJBFqZ-erYzVouT8yOb9TwqchglLQOCYTRn7ZGxI/image-asset.jpeg';
        const imageBounds: L.LatLngBoundsExpression = [
            [config.minLatitude, config.minLongitude],
            [config.maxLatitude, config.maxLongitude]
        ];
        return L.imageOverlay(url, imageBounds);
    }

    private static getBoundsFromConfig(config: HeatmapConfig): L.LatLngBounds {
        return new L.LatLngBounds([
            [config.minLatitude, config.minLongitude],
            [config.maxLatitude, config.maxLongitude]
        ]);
    }

        getHeatmapConfigs(): void {
        this.getAllConfigs().subscribe(configs => {
           this.heatmapConfigs.length = 0;
           this.heatmapConfigs.push(HeatmapConfigFactory.empty());
           this.heatmapConfigs.push(...configs);
        });
    }

    public onHeatmapSelected(heatmapConfig: HeatmapConfig, mapObject: L.Map): void {
        this.currentHeatmapConfig = heatmapConfig;
        this.selectedHeatmapConfig = heatmapConfig;
        if (this.imageOverlay) {
            this.imageOverlay.removeFrom(mapObject);
        }
        if (heatmapConfig.id > 0) {
            this.imageOverlay = this.createImageOverlayFromHeatmapConfig(heatmapConfig);
            // this.map.addLayer(this.createBlackBackground(heatmapConfig));
            mapObject.addLayer(this.imageOverlay);
        }
    }

    private updateImageOverlay(): void {
        if (this.currentHeatmapConfig && this.currentHeatmapConfig.id > 0) {
            this.imageOverlay.setBounds(HeatmapService.getBoundsFromConfig(this.currentHeatmapConfig));
            this.imageOverlay.setUrl(this.getUrlFromConfig(this.currentHeatmapConfig));
        }
    }

    private getUrlFromConfig(config: HeatmapConfig): string {

        const step = config.intervalInMinutes * 60 * 1000;
        const dateConfig = this.globalDateService.currentDateConfig();
        let time: Date;
        if (this.playerDateOverride !== null){
            time = this.playerDateOverride;
        } else if (dateConfig.most_recent_measurements && !dateConfig.measurement_duration) {
            time = new Date();
        } else {
            time = new Date(dateConfig.measurement_start.getTime() + dateConfig.measurement_duration * 1000);
        }

        time = new Date(Math.floor((time.getTime() + step * (1.0 / 2.0)) / step) * step);
        // assumption that the heatmap will generate in one minute time
        while (time.getTime() > (new Date().getTime() - 1000 * 60)){
            time = new Date(time.getTime() - step);
        }
        let timeStr = time.toISOString().replace('T', '-').replace(':', '-');
        timeStr = timeStr.split(':')[0].split('-').join('_');
        return `${environment.url}/media/heatmap/${config.name}/${config.sensorTypeName}/${timeStr}.png`;
    }

    private createImageOverlayFromHeatmapConfig(config: HeatmapConfig): ImageOverlay {
        const imageUrl = this.getUrlFromConfig(config);
        const bounds = HeatmapService.getBoundsFromConfig(config);
        return L.imageOverlay(imageUrl, bounds);
    }


    refreshHeatmap(mapObject: L.Map): void {
        if (this.selectedHeatmapConfig && this.selectedHeatmapConfig.id > 0) {
            this.onHeatmapSelected(this.selectedHeatmapConfig, mapObject);
        }
    }


    private getAllConfigs(): Observable<HeatmapConfig[]> {
        const url = `${environment.url}/heatmap/heatmapConfig/`;
        return this.http.get<IHeatmapConfig[]>(url)
            .pipe(map(configs => configs.map(config => HeatmapConfigFactory.fromBackend(config))));
    }

    playerConfigChange(config: PlayerConfig): void {
        if (!config.playing) {
            this.playerDateOverride = null;
            this.bouncerService.execute(() => {
                this.updateImageOverlay();
            });
        }
    }

    playerFrameChange(date: { date: Date | null }): void {
        this.playerDateOverride = date.date;
        this.bouncerService.execute(() => {
            this.updateImageOverlay();
        });
    }
}
