export class LineChartData {
    name: string;
    type = 'line';
    color: string;
    showInLegend = true;
    dataPoints: DataPoint[];
    deviceSensorId: number;
    maxGap: number;

    constructor(object: {name: string, color: string, dataPoints: DataPoint[], deviceSensorId: number, maxGap: number}) {
        this.name = object.name;
        this.color = object.color;
        this.dataPoints = object.dataPoints;
        this.deviceSensorId = object.deviceSensorId;
        this.maxGap = object.maxGap;
    }
}

export class DataPoint {
    x: Date;
    y: number;

    constructor(object: {x: Date, y: number}) {
        this.x = object.x;
        this.y = object.y;
    }
}
