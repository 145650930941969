/* tslint:disable:variable-name */
import {ISensorType, SensorType} from './device-sensor';

export class ISensor {
    id: number;
    sensor_type: ISensorType;
}

export class Sensor implements ISensor {
    id: number;
    sensor_type: SensorType;

    constructor(sensor: ISensor) {
        this.id = sensor.id;
        this.sensor_type = new SensorType(sensor.sensor_type);
    }
}

export interface IDevice {
    id: number;
    owner_id: number | null;
    mobility_type: string;
    model_id: string;
    name: string;
    public: boolean;
    description: string;
    sensors: ISensor[];
}

export class Device implements IDevice {
    description: string;
    id: number;
    mobility_type: string;
    model_id: string;
    name: string;
    owner_id: number | null;
    public: boolean;
    sensors: ISensor[];


    constructor(device: IDevice) {
        this.description = device.description;
        this.id = device.id;
        this.mobility_type = device.mobility_type;
        this.model_id = device.model_id;
        this.name = device.name;
        this.owner_id = device.owner_id;
        this.public = device.public;
        this.sensors = device.sensors.map(s => new Sensor(s));
    }

    static deviceIdToSensorTypeListMap(devices: Device []): Map<number, SensorType> {
        // console.log('devices', devices);
        const deviceIdToSensorType = new Map<number, SensorType>();
        for (const device of devices) {
            for (const sensor of device.sensors) {
                deviceIdToSensorType.set(sensor.id, sensor.sensor_type);
            }
        }
        return deviceIdToSensorType;
    }


}
