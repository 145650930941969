import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../services/alert.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    checkPasswords(group: FormGroup): void {
        const pass = group.get('password').value;
        const confirm = group.get('password_confirm').value;
        if (pass !== confirm) {
            group.get('password_confirm').setErrors({notSame: true});
        }
    }

    checkEmails(group: FormGroup): void {
        const mail = group.get('username').value;
        const confirm = group.get('email').value;
        if (mail !== confirm) {
            group.get('username').setErrors({notSameMail: true});
        }
    }

    highlightInvalid(errors): void {
        const details = errors.error_str;
        for (const [fieldName, detail] of Object.entries(details)) {
            const field = this.registerForm.get(fieldName);
            if (field) {
                let str = '';
                for (const [key, value] of Object.entries(detail)) {
                    str += value + ' ';
                }
                field.setErrors({server: str});
            }
        }
    }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            username: ['', [Validators.required, Validators.email]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            password_confirm: ['', [Validators.required]]
        }, {
            validator: [this.checkPasswords, this.checkEmails]
        });
    }

    // convenience getter for easy access to form fields
    get f(): { [key: string]: AbstractControl } {
        return this.registerForm.controls;
    }

    onSubmit(): void {
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            console.log(this.registerForm);
        }

        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.router.navigate(['/']);
                },
                (error) => {
                    console.log(error);
                    this.highlightInvalid(error);
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
