import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {ProfileBackendService} from '../services-backend/profile-backend.service';
import {UserProfile} from '../models/user-profile';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SensorType} from '../models/device-sensor';
import {SensorTypeBackendService} from '../services-backend/sensor-type-backend.service';

@Injectable({
    providedIn: 'root'
})
export class SensorTypeResolverService implements Resolve<Map<number, SensorType>> {

    public sensorTypes: Map<number, SensorType> = new Map<number, SensorType>();

    constructor(private sensorTypeBackendService: SensorTypeBackendService) {
    }

    resolve(): Observable<Map<number, SensorType>> | Map<number, SensorType> {
        if (this.sensorTypes.size > 0){
            return this.sensorTypes;
        }
        else {
            return this.sensorTypeBackendService.getAll().pipe(map(sensorTypes => {
                this.sensorTypes.clear();
                for (const sensorType of sensorTypes){
                    this.sensorTypes.set(sensorType.id, sensorType);
                }
                return this.sensorTypes;
            }));
        }
    }
}
