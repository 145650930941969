<mat-table [dataSource]="devices" *ngIf="devices" class="mat-effects">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef >Nazwa</mat-header-cell>
        <mat-cell *matCellDef="let device">
            <input matInput placeholder="Nazwa..." [value]="device.name"
                   (change)="updateDeviceName(device, $event)">
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="model_id">
        <mat-header-cell *matHeaderCellDef >Nr seryjny</mat-header-cell>
        <mat-cell *matCellDef="let device"> {{ device.model_id }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef >Opis</mat-header-cell>
        <mat-cell *matCellDef="let device">
            <input matInput placeholder="Opis..." [value]="device.description"
                   (change)="updateDeviceDescription(device,$event)">
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="public">
        <mat-header-cell *matHeaderCellDef ></mat-header-cell>
        <mat-cell *matCellDef="let device">
            <mat-select [(value)]="device.public" (selectionChange)="updatePublic(device, $event)">
                <mat-option [value]="true">
                    Publiczny
                </mat-option>
                <mat-option [value]="false">
                    Prywatny
                </mat-option>
            </mat-select> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">Akcje</mat-header-cell>
        <mat-cell *matCellDef="let device" class="justify-content-end">
            <button (click)="deleteDevice(device)">Usuń urządzenie</button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: false"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
</mat-table>
