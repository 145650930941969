<button *ngIf="dashboard" class="add-widget" (click)="router.navigate(['dashboard', dashboard.id, 'createWidget']);">
	Dodaj widget +
</button>
<app-player></app-player>
<app-global-date></app-global-date>
<div class="menu">
	<mat-icon (click)="showMenu()">
		settings
	</mat-icon>
	<div class="user" [ngClass]="{'open': isMenuOpened}">
		<button (click)="router.navigate(['settings/organization'])" *ngIf="showLink">Ustawienia organizacji</button>
		<button (click)="router.navigate(['settings/user'])">Ustawienia użytkownika</button>
		<app-mqtt></app-mqtt>
		<button class="logout" (click)="authenticationService.logout()">&times;&nbsp;&nbsp;&nbsp;wyloguj</button>
	</div>
</div>