import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ISensorType, SensorType} from '../models/device-sensor';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ErrorHandlerService} from '../services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class SensorTypeBackendService {

    constructor(
        private httpClient: HttpClient,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    getAll(): Observable<SensorType[]> {
        return this.errorHandlerService.pipeThroughErrorHandler(this.httpClient.get<ISensorType[]>
        (`${environment.url}/sensorTypes/`))
            .pipe(map((res: ISensorType[]) => res.map(sensorType => new SensorType(sensorType))));
    }

}
