import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import {IRegisterResponse} from '../models/register-user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<IRegisterResponse>;
    public currentUser: Observable<IRegisterResponse>;

    constructor(
        private router: Router,
        private http: HttpClient,
    ) {
        this.currentUserSubject = new BehaviorSubject<IRegisterResponse>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): IRegisterResponse {
        return this.currentUserSubject.value;
    }

    login(login, password): Observable<IRegisterResponse> {
        return this.http.post<IRegisterResponse>(`${environment.url}/accounts/login/regular/`, { login, password })
            .pipe(map(user => {
                console.log(user);
                this.setToken(user);
                return user;
            }));
    }

    logout(): void {
        // remove user from local storage and set current user to null
        this.http.post<any>(`${environment.url}/accounts/logout/`, '').subscribe(() => {
            localStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);
            location.href = '/login';
        }, () => {
            localStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);
            location.href = '/login';
        });
    }

    setToken(token: IRegisterResponse): void {
        console.log(token);
        localStorage.setItem('currentUser', JSON.stringify(token));
        this.currentUserSubject.next(token);
    }

    loginFacebook(accessToken): Observable<IRegisterResponse>  {
        return this.http.post<any>(`${environment.url}/accounts/login/facebook/`, { token: accessToken })
            .pipe(map(user => {
                console.log(user);
                this.setToken(user);
                return user;
            }));
    }

    loginGoogle(accessToken): Observable<IRegisterResponse> {
        return this.http.post<any>(`${environment.url}/accounts/login/google/`, { token: accessToken })
            .pipe(map(user => {
                console.log(user);
                this.setToken(user);
                return user;
            }));
    }
}
