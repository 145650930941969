import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DashboardCreateComponent} from './dashboard-create.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    template: ''
})
export class DashboardCreateDialogComponent {

    constructor(public dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute) {
        this.openDialog();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DashboardCreateComponent);
        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['../'], { relativeTo: this.route });
        });
    }

}
