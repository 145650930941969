import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Dashboard} from '../../models/dashboard';
import {DashboardBackendService} from '../../services-backend/dashboard-backend.service';
import {ProfileResolverService} from '../../resolvers/profile-resolver.service';
import {PlayerService} from '../../services/player.service';
import {MainLoaderService} from './main-loader.service';
import {UserAccountResolverService} from '../../resolvers/user-account-resolver.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [PlayerService, ],
})
export class MainComponent implements OnInit {
    public selectedDashboard?: Dashboard;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dashboardService: DashboardBackendService,
        private profileResolver: ProfileResolverService,
        private userAccountResolverService: UserAccountResolverService,
        private router: Router,
        public mainLoaderService: MainLoaderService,
    )
    { }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(_ => this.refreshDashboard());
    }

    refreshDashboard(): void{
        if (this.activatedRoute.snapshot.paramMap.has('id')){
            this.userAccountResolverService.observable().subscribe(userAccount => {
                const dashboardId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
                this.dashboardService.getDashboard(dashboardId, userAccount.active_organization
                ).subscribe(dashboard => {
                    this.selectedDashboard = dashboard;
                });
            });
        }
        else {
            if (this.profileResolver.profile.current_dashboard){
                this.router.navigate(['dashboard', this.profileResolver.profile.current_dashboard]);
            }
        }
    }

}
