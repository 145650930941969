import {EMPTY, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';

export class CallbackBouncerService {

    private readonly bounceMillis: number;
    private callbackToExecute: () => void = null;
    private lastExecutedCallback: number = null;

    constructor(bounceMillis: number) {
        this.bounceMillis = bounceMillis;
    }

    execute(callback: () => void): void {
        const now = (new Date()).getTime();
        this.callbackToExecute = callback;
        if (this.lastExecutedCallback === null || (this.lastExecutedCallback + this.bounceMillis) < now){
            this.lastExecutedCallback = now;
            callback();
        } else {
            const delayTime = this.getDelayTime();
            of(callback).pipe(
            delay(delayTime),
            switchMap(oldCallback => {
                    if (oldCallback === this.callbackToExecute) {
                        this.lastExecutedCallback = Date.now();
                        this.callbackToExecute();
                    }
                    return EMPTY;
                }
            )).subscribe(() => {});
        }
    }

    private getDelayTime(): number {

        const now = Date.now();
        const timeFromLastRequest = now - this.lastExecutedCallback;
        if (timeFromLastRequest >= this.bounceMillis){
            this.lastExecutedCallback = now;
            return 0;
        }
        else if (timeFromLastRequest >= 0){
            return this.bounceMillis - timeFromLastRequest;
        }
        else {
            console.error('Invalid time from last request', timeFromLastRequest);
            return 0;
        }
    }

}
