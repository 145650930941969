import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Organization} from '../model/organization';
import {MatTable} from '@angular/material/table';
import {OrganizationBackendService} from '../organization-backend.service';
import {AlertService} from '../../../services/alert.service';
import {UserInvite} from '../model/user-invite';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-organization-invites',
    templateUrl: './organization-invites.component.html',
    styleUrls: ['./organization-invites.component.scss']
})
export class OrganizationInvitesComponent implements OnInit {

    availablePermissions: {code: 'read_all' | 'admin', name: string}[]
        = [{code: 'admin', name: 'Administrator'}, {code: 'read_all', name: 'Użytkownik'}];

    constructor(
        private organizationBackendService: OrganizationBackendService,
        private alertService: AlertService,
    ) { }

    public userInvites: UserInvite[] = [];

    @Input() organization: Organization;
    @ViewChild(MatTable) matPositions: MatTable<any>;
    columnsToDisplay: string[] = ['user', 'permissions', 'actions'];

    public inviteButtonEnabled = false;
    public newInviteEmail = '';

    private static deleteFromArray<T>(array: T[], object: T): void{
        const index = array.indexOf(object);
        if (index !== -1){
            array.splice(index, 1);
        }
    }

    ngOnInit(): void {
        this.refreshData();
    }

    private refreshData(): void {
        this.organizationBackendService.getInvitesForOrganization(this.organization.id).subscribe(ret => {
            this.userInvites.length = 0;
            for (const userInvite of ret) {
                userInvite.selectedPermission = this.getSelectedPermission(userInvite);
            }
            this.userInvites.push(...ret);
            this.matPositions.renderRows();
        });
    }

    deleteInvite(userInvite: UserInvite): void {
        this.organizationBackendService.deleteInvite(userInvite).subscribe(_ => {
            this.alertService.success('Zaproszenie zostało usunięte');
            OrganizationInvitesComponent.deleteFromArray(this.userInvites, userInvite);
            this.matPositions.renderRows();
        });
    }

    public inviteEmailChange(event: Event): void {
        this.newInviteEmail = (event.target as HTMLInputElement).value;
        this.inviteButtonEnabled = this.newInviteEmail !== '';
    }

    public sendInvite(): void {
        this.organizationBackendService.createInvite(this.organization.id, this.newInviteEmail,
            {permission: 'read_all'})
            .subscribe(userInvite => {
                this.alertService.success('Wysłano zaproszenie');
                this.inviteButtonEnabled = false;
                this.newInviteEmail = '';
                userInvite.selectedPermission = this.getSelectedPermission(userInvite);
                this.userInvites.push(userInvite);
                this.matPositions.renderRows();
            });
    }

    private getSelectedPermission(userInvite: UserInvite): {code: 'read_all' | 'admin', name: string} {
        let permission = 'read_all';
        if (userInvite.permissions.findIndex(el => el.permission === 'admin') !== -1){
           permission = 'admin';
        }
        const index = this.availablePermissions.findIndex(el => el.code === permission);
        if (index !== -1){
            return this.availablePermissions[index];
        }
        else {
            return null;
        }
    }

    updateInvitePermissions(userInvite: UserInvite, event: MatSelectChange): void {
        const value: {code: 'admin' | 'read_all', name: string} = event.value;
        userInvite.permissions.length = 0;
        if (value.code === 'admin'){
            userInvite.permissions.push({permission: 'read_all'}, {permission: 'admin'});
        }
        else if (value.code === 'read_all') {
            userInvite.permissions.push({permission: 'read_all'});
        }
        this.organizationBackendService.editInvite(userInvite)
            .subscribe(_ => {
                this.alertService.success('Zaktualizowano zaproszenie');
            });
    }
}
