import {SensorType} from './device-sensor';


export interface IMeasurement {
    value: number;
    measurement_time: string;
    latitude: string;
    longitude: string;
    device_sensor_id: number;
}

export class Measurement{
    value: number;
    measurementTime: Date;
    latitude: number;
    longitude: number;
    deviceSensorId: number;
    sensorType: SensorType;


    private constructor(value: number, measurementTime: Date, latitude: number, longitude: number,
                        deviceSensorId: number, sensorType: SensorType) {
        this.value = value;
        this.measurementTime = measurementTime;
        this.latitude = latitude;
        this.longitude = longitude;
        this.deviceSensorId = deviceSensorId;
        this.sensorType = sensorType;
    }

    public static getFromIMeasurement(iMeasurement: IMeasurement, sensorType: SensorType): Measurement{
        return new Measurement(iMeasurement.value, new Date(iMeasurement.measurement_time),
            parseFloat(iMeasurement.latitude), parseFloat(iMeasurement.longitude), iMeasurement.device_sensor_id,
            sensorType);
    }

    public static getFromValues(value: number, measurementTime: Date, latitude: number, longitude: number,
                                deviceSensorId: number, sensorType: SensorType): Measurement {
        return new Measurement(value, measurementTime, latitude, longitude, deviceSensorId, sensorType);
    }
}
