<mat-table [dataSource]="userApplications" *ngIf="userApplications" class="mat-effects">

    <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef >Użytkownik</mat-header-cell>
        <mat-cell *matCellDef="let userApplication"> {{ userApplication.user_data.email }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">Akcje</mat-header-cell>
        <mat-cell *matCellDef="let userApplication" class="justify-content-end">
            <button (click)="acceptApplication(userApplication)">Zaakceptuj</button>
            <button (click)="denyApplication(userApplication)">Odrzuć</button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: false"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
</mat-table>
