import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { ChartMeasurementConverterService } from '../../services/chart-measurement-converter.service';
import { Widget } from '../../models/widget';
import { ChartLineSerie, ChartMeasurements } from '../../models/measurement-chart';
import {Router} from '@angular/router';
import {Device} from '../../models/device';
import {Measurement} from '../../models/measurement';
import {DataPoint, LineChartData} from '../../models/line-chart-data';
import {WidgetFocusService} from '../../services/widget-focus.service';
import {PlayerConfig} from '../../services/player.service';
import {GenericWidgetService, IWidgetComponent} from '../../services/generic-widget.service';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    providers: [GenericWidgetService],
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy, IWidgetComponent {

    constructor(
        private chartMeasurementConverterService: ChartMeasurementConverterService,
        private router: Router,
        private widgetFocusService: WidgetFocusService,
        public genericWidgetService: GenericWidgetService,
    ) {
    }

    @Input() public devices: Device[];
    @Input() public widgetConfig: Widget;

    chartData: LineChartData[];
    public measurements: ChartMeasurements = new ChartMeasurements([]);
    chart: CanvasJS.Chart;
    cashedItem: { data: any, text: string };
    chartId = 'Chart' + Math.floor(Math.random() * 1000);

    ngOnInit(): void {
        this.genericWidgetService.onInit(this.widgetConfig, this.devices);

    }

    public ngAfterViewInit(): void {

        this.chart = new CanvasJS.Chart(this.chartId, {
            animationEnabled: true,
            zoomEnabled: true,
            dataPointWidth: 1,
            axisY: {
                title: 'Values',
                includeZero: true
            },
            axisX: {
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true,
                    labelFormatter: (e => {
                        return CanvasJS.formatDate( e.value, 'YYYY-M-D HH:mm');
                    }
                    ),
                },
                labelFormatter: (e => {
                    if (this.measurements.getDaySpan() > 1){
                        return CanvasJS.formatDate( e.value, 'MM-DD');
                    }
                    else {
                        return CanvasJS.formatDate( e.value, 'HH:mm');
                    }
                }),
            },
            legend: {
                cursor: 'pointer',
                fontSize: 16,
                itemclick: (e) => {
                    e.dataSeries.visible = !(typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible);
                    this.chart.render();
                }
            },
            toolTip: {
                shared: true,
                content: (e) => this.hover(e)
            },
            data: this.chartData
        });

        this.chart.render();
        this.genericWidgetService.onAfterInit(this);
    }

    public ngOnDestroy(): void {
        this.genericWidgetService.onDestroy();
    }

    onMeasurementsDownload(measurements: Measurement[]): void {
        this.measurements = this.chartMeasurementConverterService.convertMeasurementsToChartData(measurements);
        this.chartData = this.chartMeasurementsToChartData(this.measurements, this.devices);
        this.chart.options.data = this.chartData;
        this.chart.render();
    }


    chartMeasurementsToChartData(chartMeasurements: ChartMeasurements, devices: Device[]): LineChartData[] {
        // this.colorScheme.domain = chartMeasurements.lineSeries.map(line => line.color);

        const preparedChartData = [];
        chartMeasurements.lineSeries.forEach((series) => {
            series.maxGap = this.calculateDifferenceInSeries(series);
            series.deviceName = devices.find(device =>
                device.sensors.findIndex(sensor =>
                    sensor.id === series.deviceSensorId) !== -1).name + ' ' + series.sensorType.code;
            for (let index = 0; index < series.measurements.length; index += 1) {
                if (index !== series.measurements.length - 1 &&
                    series.measurements[index + 1].date.getTime() - series.measurements[index].date.getTime() > series.maxGap) {
                    series.measurements.splice(index + 1, 0, {date: new Date(series.measurements[index].date.getTime() + 1), value: null});
                    index += 1;
                }
            }
            preparedChartData.push(new LineChartData({
                name: series.deviceName,
                color: series.color,
                deviceSensorId: series.deviceSensorId,
                maxGap: series.maxGap,
                dataPoints: series.measurements.map(measurement => new DataPoint({
                    x: measurement.date,
                    y: measurement.value
                }))
            }));
        });
        return preparedChartData;
    }

    calculateDifferenceInSeries(series: ChartLineSerie): number {
        const percentage = 0.75;
        const multiply = 20;
        const difference = [];

        for (let index = 0; index < series.measurements.length; index += 1) {
            if (index > 0) {
                const measurement = series.measurements[index];
                const previousMeasurement = series.measurements[index - 1];
                difference.push(measurement.date.getTime() - previousMeasurement.date.getTime());
            }
        }

        difference.sort((a, b) => a - b);
        return difference[Math.floor(percentage * difference.length)] * multiply;
    }

    hover(
        e
    ): string {
        if (e.entries.length > 0 && (this.cashedItem === undefined || this.cashedItem.data !== e)) {
            this.cashedItem = {data: e, text: ''};
            this.measurements.lineSeries.forEach(line => {
                const itemIndex = e.entries.findIndex(y => (line.deviceName) === y.dataSeries.name);
                if (itemIndex === -1) {
                    let L = 0;
                    let R = line.measurements.length - 1;
                    while (L < R) {
                        const m = Math.floor((L + R) / 2);
                        if (line.measurements[m].date > e.entries[0].dataPoint.x) {
                            R = m;
                        } else {
                            L = m + 1;
                        }
                    }
                    if (R === 0 || e.entries[0].dataPoint.x.getTime() - line.measurements[R].date.getTime() <
                        line.measurements[R - 1].date.getTime() - e.entries[0].dataPoint.x.getTime()) {
                        if (line.measurements[R].value !== null &&
                            e.entries[0].dataPoint.x.getTime() - line.measurements[R].date.getTime() < line.maxGap) {
                            this.cashedItem.text +=
                                '<tag style="color: ' + line.color + '">' +
                                line.deviceName
                                + ': '
                                + '</tag>'
                                + line.measurements[R].value.toFixed(2)
                                + ' '
                                + line.sensorType.units
                                + '<br>';
                        }
                    } else {
                        if (line.measurements[R - 1].value !== null &&
                            e.entries[0].dataPoint.x.getTime() - line.measurements[R - 1].date.getTime() < line.maxGap) {
                            this.cashedItem.text +=
                                '<tag style="color: ' + line.color + '">' +
                                line.deviceName
                                + ': '
                                + '</tag>'
                                + line.measurements[R - 1].value.toFixed(2)
                                + ' '
                                + line.sensorType.units
                                + '<br>';
                        }
                    }
                } else {
                    this.cashedItem.text +=
                        '<tag style="color: ' + line.color + '">'
                        + e.entries[itemIndex].dataSeries.name
                        + ': '
                        + '</tag>'
                        + e.entries[itemIndex].dataPoint.y.toFixed(2)
                        + ' '
                        + line.sensorType.units
                        + '<br>';
                }
            });
        }
        return this.cashedItem.text;
    }

    onClick(event: MouseEvent): void {
        this.widgetFocusService.emitValue(new Date(this.chart.axisX[0].convertPixelToValue(event.offsetX)));
    }

    appendMeasurements(measurements: Measurement[]): void {
        measurements = measurements.filter(
            measurement => this.widgetConfig.device_sensors.includes(measurement.deviceSensorId));
        if (this.measurements.lineSeries.length === 0){
            this.measurements = this.chartMeasurementConverterService.convertMeasurementsToChartData(measurements);
            this.chartData = this.chartMeasurementsToChartData(this.measurements, this.devices);
            this.chart.options.data = this.chartData;
            this.chart.render();
        }
        else {
            this.chartMeasurementConverterService.appendMeasurements(this.chartData, measurements);
            if (this.measurements.maxDate < measurements[0].measurementTime) {
                this.measurements.maxDate = measurements[0].measurementTime;
            }
            this.chart.render();
        }
    }

    public focusDate(date: Date): void {
        this.chart.toolTip.showAtX(date);
        if (this.chart.axisX.length > 0) {
            this.chart.axisX[0].crosshair.showAt(date);
        }
    }

    public playerFrameChange(date: { date: Date | null }): void {
        if (date.date !== null) {
            this.focusDate(date.date);
        }
    }

    public playerConfigChange(date: PlayerConfig): void {
        if (date.currentDate !== null) {
            this.focusDate(date.currentDate);
        }
    }
}
