import { Injectable } from '@angular/core';
import {Measurement} from '../models/measurement';
import {ChartLineSerie, ChartMeasurement, ChartMeasurements} from '../models/measurement-chart';
import {DataPoint, LineChartData} from '../models/line-chart-data';
import {Line} from 'tslint/lib/verify/lines';

@Injectable({
    providedIn: 'root'
})
export class ChartMeasurementConverterService {

    constructor() { }



    convertMeasurementsToChartData(measurements: Measurement[]):
        ChartMeasurements {
        const predefinedColors = ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'];
        const deviceSensorIdToLineSeries = new Map<number, ChartLineSerie>();
        const chartMeasurements = new ChartMeasurements([]);

        for (const measurement of measurements) {
            const deviceSensorId = measurement.deviceSensorId;
            const sensorType = measurement.sensorType;
            if (!deviceSensorIdToLineSeries.has(deviceSensorId)) {
                let color = predefinedColors.shift();
                if (color === undefined) {
                    color = this.generateColor(deviceSensorId);
                }
                deviceSensorIdToLineSeries.set(deviceSensorId, new ChartLineSerie(
                    sensorType,
                    deviceSensorId,
                    [],
                    color
                ));
            }
            const lineSerie = deviceSensorIdToLineSeries.get(deviceSensorId);
            lineSerie.measurements.push(new ChartMeasurement(measurement.measurementTime, measurement.value));
        }

        for (const lineSerie of deviceSensorIdToLineSeries.values()) {
            chartMeasurements.lineSeries.push(lineSerie);
        }
        if (measurements.length > 0) {
            chartMeasurements.minDate = measurements[0].measurementTime;
            chartMeasurements.maxDate = measurements[measurements.length - 1].measurementTime;
        }
        else {
            chartMeasurements.minDate = new Date();
            chartMeasurements.maxDate = new Date();
        }
        return chartMeasurements;
    }

    appendMeasurements(chartMeasurements: LineChartData[], measurements: Measurement[]): void {
        const deviceSensorIdToLineChart: Map<number, LineChartData> = new Map<number, LineChartData>();
        for (const chartMeasurement of chartMeasurements){
            deviceSensorIdToLineChart.set(chartMeasurement.deviceSensorId, chartMeasurement);
        }

        for (const measurement of measurements){
            if (!deviceSensorIdToLineChart.has(measurement.deviceSensorId)){
                const lineChartData = new LineChartData({
                    name: 'unknown', // TODO
                    color: undefined,
                    dataPoints: [],
                    deviceSensorId: measurement.deviceSensorId,
                    maxGap: 1000 * 60,
                });
                deviceSensorIdToLineChart.set(measurement.deviceSensorId, lineChartData);
                chartMeasurements.push(lineChartData);
            }
            const lineChart = deviceSensorIdToLineChart.get(measurement.deviceSensorId);

            if (lineChart.dataPoints.length > 0) {
                const lastMeasurementTime: Date = lineChart.dataPoints[lineChart.dataPoints.length - 1].x;
                if (measurement.measurementTime.getTime() - lastMeasurementTime.getTime() > lineChart.maxGap) {
                     lineChart.dataPoints.push(new DataPoint({
                         x: lastMeasurementTime,
                         y: null,
                     }));
                }
            }

            lineChart.dataPoints.push(new DataPoint({x: measurement.measurementTime, y: measurement.value}));
        }
    }

    generateColor(deviceId: number): string {
        return '#' + ( 0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
    }

}
