import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Measurement} from '../../models/measurement';
import {WidgetFocusService} from '../../services/widget-focus.service';
import {PlayerConfig} from '../../services/player.service';
import {TableMeasurementConverterService} from '../../services/table-measurement-converter.service';
import {Device} from '../../models/device';
import {Widget} from '../../models/widget';
import {MeasurementTable} from '../../models/measurement-table';
import {GenericWidgetService, IWidgetComponent} from '../../services/generic-widget.service';

interface GoogleFormatter {
    format(dataTable: GoogleVisualizationDataTable, column: number): void;
}

interface Google {
    charts: {
        load(
            version: string,
            settings: {
                packages: Array<string>
            }): void
    };
    visualization: {
        DateFormat: new (settings: {pattern: string}) => GoogleFormatter
        arrayToDataTable: new (array: Array<Array<Date | string | number>>) => GoogleVisualizationDataTable;
        Table: new (element: HTMLElement) => GoogleVisualizationTable;
        events: {
            addListener(table: GoogleVisualizationTable, arg2: string, callback: () => void): void
        }
        dataTableToCsv(dataTable: GoogleVisualizationDataTable): string
    };
}

declare var google: Google;


interface GoogleVisualizationDataTable {
    getValue(row: number, column: number): Date | number | string;
    addRow(row: Array<Date | string | number>): void;

    sort(columns: { column: number; desc: boolean }): void;
}

interface GoogleVisualizationTable {
    draw(dataTable: GoogleVisualizationDataTable): void;
    setSelection(selection: Array<{row: number | null, column: number | null}>): void;
    getSelection(): Array<{row: number | null, column: number | null}>;
}


@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [GenericWidgetService],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy, IWidgetComponent {

    constructor(
        private tableMeasurementConverterService: TableMeasurementConverterService,
        private widgetFocusService: WidgetFocusService,
        public genericWidgetService: GenericWidgetService,
        private elem: ElementRef,
    ) {
       google.charts.load('current', {packages: ['corechart', 'table']});
    }


    @Input() public devices: Device[];
    @Input() public widgetConfig: Widget;

    public measurements: MeasurementTable;

    private googleVisualizationDataTable: GoogleVisualizationDataTable;
    private googleVisualizationTable: GoogleVisualizationTable;


    private static binSearch(collection: Array<any>, field: string, searchValue: any): number {
        if (collection.length === 0){
            return -1;
        }
        let start = 0;
        let end = collection.length - 1;
        while (start + 1 < end){
            const middle = Math.floor((start + end) / 2);
            const value = collection[middle][field];
            if (value === searchValue){
                return middle;
            }
            else if (value < searchValue){
                start = middle;
            }
            else {
                end = middle;
            }
        }
        const distanceToStart = searchValue - collection[start][field];
        const distanceToEnd = collection[end][field] - searchValue;
        if (distanceToEnd < distanceToStart) {
            return end;
        }
        else {
            return start;
        }
    }

    private scrollOnToSelectPosition(): void {
        const tableElement = this.elem.nativeElement.querySelectorAll('.google-visualization-table-table')[0];
        const selection = tableElement.getElementsByClassName('google-visualization-table-tr-sel');
        if (selection.length) {
            // console.log(selection)
            const item = selection[0] as HTMLElement;
            const parentDiv = tableElement.parentElement;
            const tableHead = tableElement.querySelectorAll('thead')[0];
            const offset = item.offsetTop - tableHead.clientHeight;
            const viewHeight = parentDiv.clientHeight - tableHead.clientHeight;
            if (offset > parentDiv.scrollTop && offset < parentDiv.scrollTop + viewHeight) {
                if (offset + item.clientHeight > parentDiv.scrollTop + viewHeight) {
                    parentDiv.scrollTop = offset + item.clientHeight - viewHeight;
                }
            } else {
                parentDiv.scrollTop = offset;
            }
        }
    }

    ngOnInit(): void {
        this.genericWidgetService.onInit(this.widgetConfig, this.devices);
    }

    public ngAfterViewInit(): void {
        this.genericWidgetService.onAfterInit(this);
    }

    public ngOnDestroy(): void {
        this.genericWidgetService.onDestroy();
    }

    onMeasurementsDownload(measurements: Measurement[]): void {
        this.measurements = this.tableMeasurementConverterService.convertMeasurementsToTableData(measurements);
        const rows = this.tableMeasurementConverterService.convertTableDataToPrimitiveValues(this.measurements);
        console.log('Table chart: rows', rows);
        this.prepareValuesForChart(rows);
    }

    public prepareValuesForChart(values: Array<Array<Date | string | number>>): void {
        this.googleVisualizationDataTable = new google.visualization.arrayToDataTable(values, );
        this.googleVisualizationDataTable.sort({column: 0, desc: true});
        const formatter = new google.visualization.DateFormat({pattern: 'YYYY-MM-dd hh:mm:ss'});
        formatter.format(this.googleVisualizationDataTable, 0);

        this.googleVisualizationTable = new google.visualization.Table(
            document.getElementById('divTableChart_' + this.widgetConfig.id));
        this.googleVisualizationTable.draw(this.googleVisualizationDataTable);

        google.visualization.events.addListener(this.googleVisualizationTable, 'select', () => {
            const selection = this.googleVisualizationTable.getSelection();
            if (selection.length > 0) {
                const date = this.googleVisualizationDataTable.getValue(selection[0].row, 0);
                this.widgetFocusService.emitValue(date as Date);
            }
        });

    }

    downloadCSV(event): void {
        const CSVdata = google.visualization.dataTableToCsv(this.googleVisualizationDataTable);
        event.target.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(CSVdata);
        event.target.download = 'table-data.csv';
        event.target.target = '_blank';
    }
    // TODO test and fix bug and reverse order
    public appendMeasurements(measurements: Measurement[]): void {
        if (this.measurements) {
            console.log('Table component: measurements', measurements);

            const tableData = this.tableMeasurementConverterService.convertMeasurementsToTableData(measurements);
            const tableMeasurements = this.tableMeasurementConverterService.convertTableDataToPrimitiveValues(tableData);

            let newSensors = false;
            for (const key of tableData.deviceSensorIds.keys()) {
                if (!this.measurements.deviceSensorIds.has(key)) {
                    newSensors = true;
                }
                this.measurements.deviceSensorIds.set(key, tableData.deviceSensorIds.get(key));
            }
            this.measurements.rows.push(...tableData.rows);
            if (!newSensors) {
                let first = true;
                for (const tableMeasurement of tableMeasurements) {
                    if (!first) {
                        this.googleVisualizationDataTable.addRow(tableMeasurement);
                    }
                    first = false;
                }
                this.googleVisualizationDataTable.sort({column: 0, desc: true});
                const formatter = new google.visualization.DateFormat({pattern: 'YYYY-MM-dd hh:mm:ss'});
                formatter.format(this.googleVisualizationDataTable, 0);
                this.googleVisualizationTable.draw(this.googleVisualizationDataTable);
            } else {
                this.prepareValuesForChart(
                    this.tableMeasurementConverterService.convertTableDataToPrimitiveValues(this.measurements));
            }
        }
    }

    public focusDate(date: Date | null): void {
        if (date && this.measurements) {
            this.googleVisualizationTable.setSelection(
                [{row: TableComponent.binSearch(this.measurements.rows, 'date', date), column: null}]);
            this.scrollOnToSelectPosition();
        }
    }

    public playerFrameChange(date: { date: Date | null }): void {
        this.focusDate(date.date);
    }

    public playerConfigChange(date: PlayerConfig): void {
        this.focusDate(date.currentDate);
    }
}
